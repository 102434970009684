import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setArr, setEmailId, putElm, setAiImages } from '../../actions/actions';
import { Html } from '@react-email/html';
import { Body } from '@react-email/body';
import { render } from '@react-email/render';
import ImageBlock from '../Constructor/viewBlocks/imageBlock';
import ImageBox from '../Constructor/attrBlocks/image.js';
import PaddingBox from '../Constructor/attrBlocks/padding';
import generateBlock from '../Constructor/viewBlocks/generateBlock';
import BackgroundBox from '../Constructor/attrBlocks/background';
import FontBox from '../Constructor/attrBlocks/font';
import AlignBox from '../Constructor/attrBlocks/align';
import DeleteBox from '../Constructor/attrBlocks/delete';
import TextBlock from '../Constructor/viewBlocks/textBlock';
import DirBox from '../Constructor/attrBlocks/dir.js';
import MarginBox from '../Constructor/attrBlocks/margin';
import LinkBox from '../Constructor/attrBlocks/link';
import { getTextBlock } from '../Constructor/renderBlocks/textBlock';
import { getImgBlock } from '../Constructor/renderBlocks/imageBlock';
import ImageButton from '../Constructor/viewBlocks/imageButton';
import { getImgButton } from '../Constructor/renderBlocks/imageButton';
import ListBlock from '../Constructor/viewBlocks/listBlock';
import { getListBlock } from '../Constructor/renderBlocks/listBlock';
import LogoBlock from '../Constructor/viewBlocks/logoBlock';
import { getLogoBlock } from '../Constructor/renderBlocks/logoBlock';
import SocialBlock from '../Constructor/viewBlocks/socialBlock';
import { getSocialBlock } from '../Constructor/renderBlocks/socialBlock';
import { getTextLinkBlock } from '../Constructor/renderBlocks/textLinkBlock';
import TextLinkBlock from '../Constructor/viewBlocks/textLinkBlock';
import Border from '../Constructor/attrBlocks/border';
import PreviewModal from '../../components/PreviewModal/PreviewModal';
import Ai_text from '../Constructor/attrBlocks/ai_text';
import { icons } from '../../assets/icons';
import { Fade } from 'react-reveal';
import { getHtmlRaw } from '../../components/getHtmlRaw';
import '../Constructor/styles.css';
import './style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AboutModal from '../../components/AboutModal/AboutModal';
import Background from '../Constructor/attrBlocks/background';
import { newEmail } from '../../components/newEmail';
import GenerateLettetModal from '../../components/GenerateLettetModal/GenerateLettetModal';
import { addTextManager } from '../../components/addText';
import DeleteCampaignModal from '../../components/DeleteCampaignModal/DeleteCampaignModal';
import test_data from '../Constructor/test_data.json';
class AIConstructor extends Component {
    constructor(props) {
        super(props);
        this.types = [
            { obj: { type: "main-block", attr: [1, 4] } },
            { obj: { type: "ai-logo-1", attr: [1, 4] } },
            { obj: { type: "ai-logo-2", attr: [1, 4] } },
            { obj: { type: "ai-logo-3", attr: [1, 4] } },
            { obj: { type: "ai-logo-4", attr: [1, 4] } },
            { obj: { type: "ai-logo-5", attr: [1, 4] } },
            { obj: { type: "ai-logo-6", attr: [1, 4] } },

            { obj: { type: "ai-list-1", attr: [1, 4] } },
            { obj: { type: "ai-list-2", attr: [1, 4] } },
            { obj: { type: "ai-list-3", attr: [1, 4] } },
            { obj: { type: "ai-list-4", attr: [1, 4] } },
            { obj: { type: "ai-list-5", attr: [1, 4] } },
            { obj: { type: "ai-list-6", attr: [1, 4] } },

            { obj: { type: "ai-offers-1", attr: [1, 4] } },
            { obj: { type: "ai-offers-2", attr: [1, 4] } },
            { obj: { type: "ai-offers-3", attr: [1, 4] } },
            { obj: { type: "ai-offers-4", attr: [1, 4] } },

            { obj: { type: "ai-header-1", attr: [1, 4] } },
            { obj: { type: "ai-header-2", attr: [1, 4] } },
            { obj: { type: "ai-header-3", attr: [1, 4] } },
            { obj: { type: "ai-header-4", attr: [1, 4] } },
            { obj: { type: "ai-header-5", attr: [1, 4] } },
            { obj: { type: "ai-header-6", attr: [1, 4] } },

            { obj: { type: "ai-footer-1", attr: [1, 4] } },
            { obj: { type: "ai-footer-2", attr: [1, 4] } },
            { obj: { type: "ai-footer-3", attr: [1, 4] } },
            { obj: { type: "ai-footer-4", attr: [1, 4] } },
            { obj: { type: "ai-footer-5", attr: [1, 4] } },
            { obj: { type: "ai-footer-6", attr: [1, 4] } },
            { obj: { type: "ai-footer-7", attr: [1, 4] } },
            { obj: { type: "ai-footer-8", attr: [1, 4] } },
            { obj: { type: "ai-footer-9", attr: [1, 4] } },

            { obj: { type: "text", attr: [1, 2, 4, 3, 7] } },
            { obj: { type: "image", attr: [1, 4, 3, 5, 7] } },
            { obj: { type: "link-img", attr: [1, 4, 3, 5, 6, 7] } },
            { obj: { type: "list-block", attr: [1, 4, 11, 3, 8, 7] } },
            { obj: { type: "text-image", attr: [1, 4, 11, 3, 8, 7] } },
            { obj: { type: "logo-block", attr: [1, 4] } },
            { obj: { type: "three-i-row", attr: [1, 4] } },
            { obj: { type: "text-two-block", attr: [1, 4] } },
            { obj: { type: "two-text-image", attr: [1, 4] } },
            { obj: { type: "three-text-image", attr: [1, 4] } },
            { obj: { type: "text-three-block", attr: [1, 4] } },
            { obj: { type: "social-block", attr: [1, 4, 7, 8] } },
            { obj: { type: "text-link", attr: [1, 9, 4, 10, 7, 2, 6,] } },

        ];
        var step = 0;
        if (props.page !== undefined && props.page !== null) {
            step = props.page;
        }
        this.state = {
            attrs: [],
            activeTab: 1,
            leftMenuControlsHeight: 0,
            openedPreviewModal: false,
            selectedBlock: -99,
            open_menu: false,
            openedAboutModal: false,
            step: step,
            loading: false,
            loadingSubject: false,
            subject: '',
            receiver: '',
            letterType: 0,
            input_data: {
                company_name: '',
                product_name: '',
                brif_zero: '',
                key_benefits: '',
                brif_one: '',
                brif_two: '',
                brif_extra: '',
            },
            openedGenerateLetterModal: false,
            openedDeleteModal: false,
            s_0_status: 0,
            prompt: ''
        };
        this.blockTypesBoxRef = React.createRef();
        this.sheetBoxRef = React.createRef();
        this.attrBoxRef = React.createRef();
        this.menuRef = React.createRef();
    }

    toggleActiveTab = (index) => {
        this.setState({ activeTab: index })
    }

    openPreviewModal = () => {
        this.setState({ openedPreviewModal: true })
    }

    closePreviewModal = () => {
        this.setState({ openedPreviewModal: false })
    }

    openAboutModal = () => {
        this.setState({ openedAboutModal: true })
    }

    closeAboutModal = () => {
        this.setState({ openedAboutModal: false })
    }

    openGenerateLetterModal = () => {
        this.setState({ openedGenerateLetterModal: true })
    }

    closeGenerateLetterModal = () => {
        this.setState({ openedGenerateLetterModal: false })
    }

    openDeleteModal = () => {
        this.setState({ openedDeleteModal: true })
    }

    closeDeleteModal = () => {
        this.setState({ openedDeleteModal: false })
        //TODO: refresh page
    }

    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    setInputdata = (type) => {
        switch (type) {
            case 0:
                this.setState({
                    input_data: {
                        company_name: '',
                        product_name: '',
                        brif_zero: '',
                        key_benefits: '',
                        brif_one: '',
                        brif_two: '',
                        brif_extra: ''
                    }
                });
                break;
            case 1:
                this.setState({
                    input_data: {
                        company_name: '',
                        product_name: '',
                        brif_zero: '',
                        key_benefits: '-1',
                        brif_one: '',
                        brif_two: '',
                        brif_extra: ''
                    }
                });
                break;
            case 2:
                this.setState({
                    input_data: {
                        company_name: '',
                        product_name: '',
                        brif_zero: '',
                        key_benefits: '',
                        brif_one: '-1',
                        brif_two: '',
                        brif_extra: ''
                    }
                });
                break;
            case 3:
                this.setState({
                    input_data: {
                        company_name: '',
                        product_name: '',
                        brif_zero: '-1',
                        key_benefits: '',
                        brif_one: '',
                        brif_two: '',
                        brif_extra: ''
                    }
                });
                break;
            case 4:
                this.setState({
                    input_data: {
                        company_name: '',
                        product_name: '',
                        brif_zero: '',
                        key_benefits: '',
                        brif_one: '-1',
                        brif_two: '',
                        brif_extra: ''
                    }
                });
                break;
            default:
                break;
        }
    }
    async decodeAiStructure(_data, img1) {
        var data = {
            "email": {
                "header": {
                    "headliner": "<p style='font-weight:bold;'>Welcome to Public Bank</p>",
                    "text": "<p>We are thrilled to welcome you as a new client of Public Bank with your new Silver Ultra Card.</p>",
                    "tags": [
                        "silver card",
                        "public bank"
                    ]
                },
                "benefits": {
                    "headliner": "<p style='font-weight:bold;'>Key Benefits of Your Silver Ultra Card</p>",
                    "list": [
                        {
                            "headliner": "<p style='font-weight:bold;'>Use your money or credit limit</p>",
                            "text": "<p>Enjoy a flexible credit limit up to 10000 USD.</p>"
                        },
                        {
                            "headliner": "<p>Pay Anywhere</p>",
                            "text": "<p>Make transactions in every EU country and online without hassle.</p>"
                        },
                        {
                            "headliner": "<p style='font-weight:bold;'>Buy Now, Pay Later</p>",
                            "text": "<p>Shop today and pay at your convenience with flexible payment options.</p>"
                        },
                        {
                            "headliner": "<p style='font-weight:bold;'>Withdraw Cash</p>",
                            "text": "<p>Access your money at any ATM using a QR code.</p>"
                        }
                    ]
                },
                "basic_features": {
                    "headliner": "<p style='font-weight:bold;'>Basic Features</p>",
                    "list": [
                        {
                            "headliner": "<p style='font-weight:bold;'>Contactless Payments</p>",
                            "text": "<p>Make fast and secure transactions with a tap.</p>"
                        },
                        {
                            "headliner": "<p style='font-weight:bold;'>Extended Warranty</p>",
                            "text": "<p>Enjoy extended warranty on purchases made with your card.</p>"
                        },
                        {
                            "headliner": "<p style='font-weight:bold;'>Security</p>",
                            "text": "<p>Your transactions are protected with our state-of-the-art security features.</p>"
                        }
                    ]
                },
                "offers": [
                    {
                        "tags": [
                            "cashback",
                            "increased benefits"
                        ],
                        "data": {
                            "headliner": "<p style='font-weight:bold;'>Increased Cashback Offer</p>",
                            "text": "<p>Enjoy up to 7 categories of increased cashback up to 6% on your purchases.</p>"
                        }
                    },
                    {
                        "tags": [
                            "lounge access",
                            "travel"
                        ],
                        "data": {
                            "headliner": "<p style='font-weight:bold;'>LoungeKey Program</p>",
                            "text": "<p>Benefit from the LoungeKey program with 10 free visits per year using your card.</p>"
                        }
                    },
                    {
                        "tags": [
                            "travel discount",
                            "airport transport"
                        ],
                        "data": {
                            "headliner": "<p style='font-weight:bold;'>Bolt Airport Trips</p>",
                            "text": "<p>Receive a 50% discount on trips to the airport and back with Bolt.</p>"
                        }
                    },
                    {
                        "tags": [
                            "car rental",
                            "discount"
                        ],
                        "data": {
                            "headliner": "<p style='font-weight:bold;'>Car Rental Discount</p>",
                            "text": "<p>Enjoy exclusive discounts on car rentals worldwide.</p>"
                        }
                    }
                ],
                "bottom": {
                    "headliner": "<p style='font-weight:bold;'>Activate Your Card</p>",
                    "text": "<p>Activate your Silver Ultra Card today! Visit <a href='https://pb.ua'>our website</a> and enter your credit card number, billing zip code, and follow the prompts.</p>"
                }
            }
        };


        if (_data !== undefined) {
            data = _data;
        }

        this.props.setArr([]);

        var e_type = 3;
        var list = [];
        var list2 = [];
        if (data.email.benefits !== undefined) {
            for (var i = 0; i < data.email.benefits.list.length; i++) {
                list.push(data.email.benefits.list[i].headliner + data.email.benefits.list[i].text);
            }
        }

        if (data.email.basic_features !== undefined) {
            for (var i = 0; i < data.email.basic_features.list.length; i++) {
                list2.push(data.email.basic_features.list[i].headliner + data.email.basic_features.list[i].text);
            }
        }
        switch (this.state.letterType) {
            case 0:
                //      break;
                this.addAILogoBlock();
                this.addAIHeader(data.email.header.headliner, data.email.header.text, img1 !== undefined ? img1 : 'https://adbotest.digitum.nl/static/media/header_1.png');
                this.addAIList(data.email.benefits.headliner, list, 'ai-list-7');
                await new Promise(r => setTimeout(r, 50));
                this.addAIOffers(data.email.offers, 'ai-offers-2');
                await new Promise(r => setTimeout(r, 50));
                this.addAIList(data.email.basic_features.headliner, list2, 'ai-list-5');
                await new Promise(r => setTimeout(r, 50));
                this.addAiFooter(data.email.bottom.headliner, data.email.bottom.text, 'ai-footer-4');
                break;
            case 1:
                //   break;
                this.addAILogoBlock('ai-logo-5');
                await new Promise(r => setTimeout(r, 50));
                this.addAIHeader(data.email.header.headliner, data.email.header.text, img1 !== undefined ? img1 : 'https://adbotest.digitum.nl/static/media/header_1.png', 'ai-header-5');
                await new Promise(r => setTimeout(r, 50));
                this.addAIList(data.email.basic_features.headliner, list2);
                this.addAIOffers(data.email.offers, 'ai-offers-3');
                await new Promise(r => setTimeout(r, 50));
                this.addAiFooter(data.email.bottom.headliner, data.email.bottom.text, 'ai-footer-3');
                break;
            case 2:
                this.addAILogoBlock('ai-logo-6');
                await new Promise(r => setTimeout(r, 50));
                this.addAIHeader(data.email.header.headliner, data.email.header.text, img1 !== undefined ? img1 : 'https://adbotest.digitum.nl/static/media/header_1.png', 'ai-header-6');
                await new Promise(r => setTimeout(r, 50));
                this.addAIList(data.email.benefits.headliner, list, 'ai-list-2');
                await new Promise(r => setTimeout(r, 50));
                this.addAIOffers(data.email.offers, 'ai-offers-4');
                await new Promise(r => setTimeout(r, 50));
                this.addAiFooter(data.email.bottom.headliner, data.email.bottom.text, 'ai-footer-7');
                break;
            case 3:
                this.addAILogoBlock();
                this.addAIHeader(data.email.header.headliner, data.email.header.text, img1 !== undefined ? img1 : 'https://adbotest.digitum.nl/static/media/header_1.png', 'ai-header-3');
                await new Promise(r => setTimeout(r, 50));
                this.addAIList(data.email.benefits.headliner, list, 'ai-list-4');
                await new Promise(r => setTimeout(r, 50));
                this.addAIOffers(data.email.offers, 'ai-offers-4');
                await new Promise(r => setTimeout(r, 50));
                this.addAiFooter(data.email.bottom.headliner, data.email.bottom.text, 'ai-footer-9');
                break;
            case 4:
                this.addAILogoBlock();
                this.addAIHeader(data.email.header.headliner, data.email.header.text, img1 !== undefined ? img1 : 'https://adbotest.digitum.nl/static/media/header_1.png', 'ai-header-3');
                await new Promise(r => setTimeout(r, 50));
                this.addAIList(data.email.benefits.headliner, list, 'ai-list-4');
                await new Promise(r => setTimeout(r, 50));
                this.addAIOffers(data.email.offers, 'ai-offers-4');
                await new Promise(r => setTimeout(r, 50));
                this.addAiFooter(data.email.bottom.headliner, data.email.bottom.text, 'ai-footer-9');
                break;
            default:


                break;
        }
        if (this.props.token !== undefined && this.props.token !== null && this.props.token.length > 2) {
            this.saveEmail(true);
        }
        this.setState({ loading: false });
        // this.addAILogoBlock();
        //  this.addAIHeader(data.email.header.headliner, data.email.header.text, img1 !== undefined ? img1 : 'https://adbotest.digitum.nl/static/media/header_1.png');

        // this.addImage(undefined, img2 !== undefined ? img2 : 'https://cdn.pixabay.com/photo/2015/04/27/11/48/sign-741813_1280.jpg');

        /*var list = [];
        for (var i = 0; i < data.email.benefits.list.length; i++) {
            list.push(data.email.benefits.list[i].headliner + data.email.benefits.list[i].text);
        }*/
        // this.addAIList(data.email.benefits.headliner, list);


        //   this.addAIOffers(data.email.offers);

        // this.addAiFooter(data.email.bottom.headliner, data.email.bottom.text, 'ai-footer-4');

        // console.log(data.email);

    }
    createAiStuff = () => {
        var json = {
            token: '21',
            words: '2',
            prompt: this.state.prompt,
            tone: '3',
            type: this.state.letterType,
            userDataGpt: this.state.input_data
        };
        fetch('ChatGPTCheap', {
            method: "POST",
            body: JSON.stringify(json),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(x => x.json()).then(x => this.decodeAiStructure(JSON.parse(x.text.replace('```json', '').replace('```', ''))));
        this.setState({ loading: false });
    }
    getCheapImage1 = (data) => {
        fetch('https://adbotest.digitum.nl/GetCheapImage?words=' + encodeURI(data.email.header.tags.join(', '))).then(x => x.json()).then(x => this.decodeAiStructure(data, x.list[0]));
    }
    getCheapImage2 = (data, img) => {
        fetch('https://adbotest.digitum.nl/GetCheapImage?words=' + encodeURI(data.email.mainImage.tags.join(', '))).then(x => x.json()).then(x => this.decodeAiStructure(data, img, x.list[0]));
    }
    addInOrder = (obj, id) => {
        this.props.arr.push(obj)
        var arr = Array.prototype.concat(this.props.arr);
        this.props.setArr(arr);
        if (id) return id;
    }

    addBlockManager = (obj, id) => {
        if (this.props.selectedElm != -99 && this.props.selectedElm != undefined) {
            if (this.props.arr.length > 0 && this.props.arr.filter(x => x.params.for === -99).length > 0 &&
                this.props.arr.find(elem => elem.id === this.props.selectedElm) !== undefined
                && this.props.arr.find(elem => elem.id === this.props.selectedElm).params.for === -99) {
                let index = null;
                for (let i = 0; i < this.props.arr.length; i++) {
                    if (this.props.arr[i].id === this.props.selectedElm) {
                        index = i;
                    }
                }
                this.props.arr.splice(index + 1, 0, obj)
                var arr = Array.prototype.concat(this.props.arr);
                this.props.setArr(arr);
                if (id) return id;
            } else {
                if (id) {
                    let res = this.addInOrder(obj, id)
                    return res
                } else {
                    this.addInOrder(obj)
                }
            }
        } else {
            if (id) {
                let res = this.addInOrder(obj, id)
                return res
            } else {
                this.addInOrder(obj)
            }
        }
    }
    addAIHeader = (header, text, img, type) => {

        var id = this.uuidv4();
        this.addText(id, header, '20px', 'left', '36.8px', '12px', '20px', '32px', '700');
        this.addText(id, text, "20px", 'left', '30px', '0px', undefined, '16px', '400');
        this.addImage(id, img, "220px");//window.location.hostname
        var _obj = {};

        _obj = {
            id: id,
            childrens: [],
            type: type === undefined ? "ai-header-1" : type,
            params: {
                paddingTop: '0px',
                paddingLeft: '0',
                paddingRight: '0',
                paddingBottom: '0px',
                dir: '',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                backgroundColor: '#fff',
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        }


        this.addBlockManager(_obj)
    }
    addAIOffers = (offers, type) => {
        var id = this.uuidv4();


        var chld = [];
        for (var i = 0; i < offers.length; i++) {

            chld.push(this.addListBlock(false, offers[i].data.text, id, 'https://adbotest.digitum.nl/static/media/offers_1.png', undefined, '0px', '0px', '21px', '160px', '16px', '#C1F926', '0px,0px,16px,0px')); //TODO: replace image with real

            this.addText(id, offers[i].data.headliner.replace('<p>', '<p style="font-size: 18px; font-weight: 700;padding-bottom: 8px;">') + offers[i].data.text, '16px', 'left', '21px', '16px', '16px', undefined, undefined, '#C1F926');
        }
        var _obj = {};

        _obj = {
            id: id,
            childrens: chld,
            type: type === undefined ? "ai-offers-1" : type,
            params: {
                paddingTop: '40px',
                paddingLeft: '24px',
                paddingRight: '24px',
                paddingBottom: '40px',
                b2_paddingRight: '0px',
                b2_paddingLeft: '0px',
                b2_paddingTop: '0px',
                b2_paddingBottom: '0px',
                dir: '',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                backgroundColor: '#C1F926',
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }

        }

        this.addBlockManager(_obj)
    }
    addAIList = (text, list, type) => {

        var id = this.uuidv4();
        this.addText(id, text, "20px", 'left', undefined, '32px', '40px', '24px', '700');

        var chld = [];
        for (var i = 0; i < list.length; i++) {
            var text = list[i];
            var count_p = text.match(/<p>/g).length;
            if (count_p === 2) {
                text = text.replace("<p>", '<p style="font-size: 19px; font-weight: 700;">').replace("<p>", '<p style="padding-top: 12px;">');
            }
            else {
                text = text.replace("<p style='font-weight:bold;'>", '<p style="font-size: 19px; font-weight: 700;">').replace("<p>", '<p style="padding-top: 12px;">');
            }
            chld.push(this.addListBlock(false, text, id, 'https://adbotest.digitum.nl/static/media/ai_list_dot1.png', undefined, '5px', '0px', '28px', undefined, undefined, undefined, '12px,32px,12px,32px'));
        }
        var _obj = {};

        _obj = {
            id: id,
            childrens: chld,
            type: type === undefined ? "ai-list-1" : type,
            params: {
                paddingTop: '12px',
                paddingLeft: '0px',
                paddingRight: '0px',
                paddingBottom: '12px',
                dir: '',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                backgroundColor: '#fff',
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }

        }

        this.addBlockManager(_obj)
    }
    addListBlock = (rtl, text, _for, img, _pB, _pT, _pR, lineHeigth, _i_width, _pL, background, padding) => {

        var id = this.uuidv4();
        this.addText(id, text, _pL === undefined ? "20px" : _pL, 'left', lineHeigth, _pB);
        var i_width = '35px';
        if (_i_width !== undefined) {
            i_width = _i_width;
        }
        if (_i_width === undefined && img !== undefined) {
            i_width = '10px';
        }
        var _img = "https://" + 'adbotest.digitum.nl' + "/static/media/default_dot.png";//window.location.hostname
        this.addImage(id, img === undefined ? _img : img, i_width, _pR === undefined ? '32px' : _pR, undefined, _pT);
        var _obj = {};
        if (rtl) {
            _obj = {
                id: id,
                childrens: [],
                type: "list-block",
                params: {
                    paddingTop: padding.split(',')[0],
                    paddingLeft: padding.split(',')[1],
                    paddingRight: padding.split(',')[3],
                    paddingBottom: padding.split(',')[2],
                    dir: 'rtl',
                    borderTopLeftRadius: '0px',
                    borderBottomLeftRadius: '0px',
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px',
                    backgroundColor: background === undefined ? '#fff' : background,
                    marginTop: '0px',
                    for: _for === undefined ? -99 : _for,
                    marginRight: '0px',
                    marginLeft: '0px',
                    marginBottom: '0px',
                }
            }
        } else {
            _obj = {
                id: id,
                childrens: [],
                type: "list-block",
                params: {
                    paddingTop: padding.split(',')[0],
                    paddingLeft: padding.split(',')[1],
                    paddingRight: padding.split(',')[3],
                    paddingBottom: padding.split(',')[2],
                    dir: '',
                    borderTopLeftRadius: '0px',
                    borderBottomLeftRadius: '0px',
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px',
                    backgroundColor: background === undefined ? '#fff' : background,
                    marginTop: '0px',
                    for: _for === undefined ? -99 : _for,
                    marginRight: '0px',
                    marginLeft: '0px',
                    marginBottom: '0px',
                }
            }
        }

        this.addBlockManager(_obj);
        return id;
    }
    addTextImage = (rtl, _width, _for) => {
        var id = this.uuidv4();
        var width = '602px';
        if (_width !== undefined && _width != null) {
            width = _width;
        }
        this.addText(id, undefined, "20px", rtl ? 'right' : 'center');
        this.addImage(id, "https://" + 'adbotest.digitum.nl' + "/static/media/default_dot.png", "35px", '0');//window.location.hostname
        var _obj = {};

        _obj = {
            id: id,
            childrens: [],
            type: "text-image",
            params: {
                paddingTop: '12px',
                paddingLeft: '32px',
                paddingRight: '32px',
                paddingBottom: '12px',
                dir: '',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                align: 'center',
                backgroundColor: '#fff',
                marginTop: '0px',
                for: _for === undefined ? -99 : _for,
                width: width,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        }


        let resId = this.addBlockManager(_obj, _obj.id);
        return resId;
    }
    addTwoTextImage = () => {
        var id = this.uuidv4();
        var block1 = this.addTextImage(false, '301px', id);
        var block2 = this.addTextImage(false, '301px', id);

        var _obj = {
            id: id,
            childrens: [block1, block2],
            type: "two-text-image",
            params: {
                paddingTop: '12px',
                paddingLeft: '32px',
                paddingRight: '32px',
                paddingBottom: '12px',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                backgroundColor: '#fff',
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        }
        this.addBlockManager(_obj)
    }
    addThreeTextImage = () => {
        var id = this.uuidv4();
        var block1 = this.addTextImage(false, '200px', id);
        var block2 = this.addTextImage(false, '200px', id);
        var block3 = this.addTextImage(false, '200px', id);
        var _obj = {
            id: id,
            childrens: [block1, block2, block3],
            type: "three-text-image",
            params: {
                paddingTop: '12px',
                paddingLeft: '32px',
                paddingRight: '32px',
                paddingBottom: '12px',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                backgroundColor: '#fff',
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        }
        this.addBlockManager(_obj)

    }
    addSocialBlock = () => {
        var id = this.uuidv4();
        var img1 = this.addImageLink(id, "https://" + window.location.hostname + "/static/media/fb_default.png", '30px', '20px');
        var img2 = this.addImageLink(id, "https://" + window.location.hostname + "/static/media/tw_default.png", '30px', '20px');
        var img3 = this.addImageLink(id, "https://" + window.location.hostname + "/static/media/insta_default.png", '30px', '20px');
        var img4 = this.addImageLink(id, "https://" + window.location.hostname + "/static/media/yt_default.png", '30px', '20px');

        var _obj = {
            id: id,
            type: 'social-block',
            childrens: [img1, img2, img3, img4],
            params: {
                paddingTop: '12px',
                paddingLeft: '0px',
                paddingRight: '0px',
                align: 'center',
                backgroundColor: '#fff',
                paddingBottom: '12px',
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        }
        this.addBlockManager(_obj)
    }
    addAILogoBlock = (type) => {
        var id = this.uuidv4();
        var img1 = this.addImage(id, "https://adbotest.digitum.nl/static/media/wink_logo.png", '75px', '24px', 'left');
        var img2 = this.addImage(id, "https://adbotest.digitum.nl/static/media/bar_logo.png", '2px', '24px', 'left');
        var img3 = this.addImage(id, "https://adbotest.digitum.nl/static/media/visa_logo.png", "76px", undefined, 'left');
        var _obj = {
            id: id,
            type: type === undefined ? 'ai-logo-1' : type,
            childrens: [img1, img2, img3],
            params: {
                paddingTop: '12px',
                paddingLeft: '24px',
                paddingRight: '24px',
                backgroundColor: '#fff',
                paddingBottom: '12px',
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        }
        this.addBlockManager(_obj)
    }

    addThreeImage = () => {
        var id = this.uuidv4();
        var width = '160px';
        var url = "https://adbotest.digitum.nl/static/media/banner_test.png";//"https://" + window.location.hostname + "/static/media/banner_test.png";
        var img1 = this.addImage(id, url, width);
        var img2 = this.addImage(id, url, width);
        var img3 = this.addImage(id, url, width);
        var _obj = {
            id: id,
            type: 'three-i-row',
            childrens: [img1, img2, img3],
            params: {
                paddingTop: '12px',
                paddingLeft: '0px',
                paddingRight: '0px',
                backgroundColor: '#fff',
                paddingBottom: '12px',
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        };
        this.addBlockManager(_obj)
    }
    addImage = (_for, _img, _width, pR, _align, _pT) => {
        var id = this.uuidv4();
        var img = "https://" + window.location.hostname + "/static/media/banner_test.png";
        var width = '350px';
        if (_img !== undefined && _img !== null) {
            img = _img;
        }
        if (_width !== undefined && _width !== null) {
            width = _width;
        }
        var paddingRight = '0';
        if (pR !== undefined && pR !== null) {
            paddingRight = pR;
        }
        var align = 'center';
        if (_align !== undefined && _align !== null) {
            align = _align;
        }
        var _obj = {
            id: id,
            type: 'image',
            childrens: [],
            params: {
                height: 'auto',
                width: width,
                paddingTop: _pT === undefined ? (_for === undefined ? '12px' : '0px') : _pT,
                align: align,
                paddingLeft: '0px',
                paddingRight: paddingRight,
                paddingBottom: _for === undefined ? '12px' : '0px',
                backgroundColor: '#fff',
                marginTop: '0px',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                for: _for === undefined ? -99 : _for,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
                alt: '',
                src: img
            }
        }
        let resId = this.addBlockManager(_obj, id)
        return resId
    }
    addImageLink = (_for, _img_link, _size, _pRight) => {
        var id = this.uuidv4();
        var img_link = _img_link === undefined ? "https://" + window.location.hostname + "/static/media/default_button.png" : _img_link;
        var size = _size === undefined ? '300px' : _size;
        var _obj = {
            id: id,
            type: 'link-img',
            childrens: [],
            params: {
                height: 'auto',
                width: size,
                paddingTop: _for === undefined ? '12px' : '0px',
                for: _for === undefined ? -99 : _for,
                align: 'center',
                paddingLeft: '0px',
                link: "https://digitum.nl",
                paddingRight: '0px',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                backgroundColor: '#fff',
                paddingBottom: _for === undefined ? '12px' : '0px',
                marginTop: '0px',
                marginRight: _pRight === undefined ? '0px' : _pRight,
                marginLeft: '0px',
                marginBottom: '0px',
                alt: '',
                src: img_link
            }
        }
        let resId = this.addBlockManager(_obj, id)
        return resId
    }
    addAiFooter = (t1, t2, type) => {
        var id = this.uuidv4();
        var text1 = this.addText(id, t1, "20px", 'left', undefined, '8px', undefined, '23px', '700', '#0368E7', 'white');
        var text2 = this.addText(id, t2, "20px", 'left', '30px', undefined, undefined, '16px', '400', '#0368E7', 'white');

        var _obj = {
            id: id,
            childrens: [text1, text2],
            type: type === undefined ? "ai-footer-1" : type,
            params: {
                paddingTop: '40px',
                paddingLeft: '24px',
                paddingRight: '24px',
                paddingBottom: '40px',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                backgroundColor: '#0368E7',
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        }
        this.addBlockManager(_obj);
        return id;
    }
    addTwoText = () => {
        var id = this.uuidv4();
        var text1 = this.addText(id, undefined, "20px");
        var text2 = this.addText(id, undefined, "20px");

        var _obj = {
            id: id,
            childrens: [text1, text2],
            type: "text-two-block",
            params: {
                paddingTop: '12px',
                paddingLeft: '32px',
                paddingRight: '32px',
                paddingBottom: '12px',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                backgroundColor: '#fff',
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        }
        this.addBlockManager(_obj)
    }
    addThreeText = () => {
        var id = this.uuidv4();
        var text1 = this.addText(id, undefined, "20px");
        var text2 = this.addText(id, undefined, "20px");
        var text3 = this.addText(id, undefined, "20px");
        var _obj = {
            id: id,
            childrens: [text1, text2, text3],
            type: "text-three-block",
            params: {
                paddingTop: '12px',
                paddingLeft: '32px',
                paddingRight: '32px',
                paddingBottom: '12px',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                backgroundColor: '#fff',
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        }
        this.addBlockManager(_obj)

    }
    addText = (_for, _text, _pLeft, align, lineHeigth, _pB, _pT, fontSize, fontWeight, background, fColor) => {
        let text = 'Paragraph: Laborum laboris mollit commodo do consectetur consectetur sunt ea velit reprehenderit ad irure aliqua amet. Excepteur esse Lorem do labore laborum reprehenderit laborum mollit dolor mollit mollit quis in. Anim nulla Lorem nostrud duis voluptate ipsum sint pariatur. Esse ex fugiat pariatur officia consequat est aliqua labore sit cillum Lorem deserunt qui mollit. Quis eu Lorem cillum dolor do do. Ea sint aliquip irure exercitation ut ut et irure aute ad.';


        if (_text != undefined && _text != null) {
            text = _text;
        }
        if (align === undefined || align === null) {
            align = 'center';
        }
        let _obj = addTextManager({
            backgroundColor: background === undefined ? '#fff' : background,
            paddingTop: _for === undefined ? '12px' : (_pT === undefined ? '0px' : _pT),
            paddingLeft: _for === undefined ? '32px' : _pLeft,
            paddingRight: _for === undefined ? '32px' : _pLeft,
            text: _for === undefined ? text : (_text === undefined ? 'Lorem ipsum' : text),
            paddingBottom: _for === undefined ? '12px' : (_pB === undefined ? '0px' : _pB),
            marginTop: '0px',
            marginRight: '0px',
            align: align,
            color: fColor === undefined ? '#000' : fColor,
            dir: '',
            for: _for === undefined ? -99 : _for,
            marginLeft: '0px',
            marginBottom: '0px',
            fontFamily: 'Arial',
            fontSize: fontSize === undefined ? '14px' : fontSize,
            fontWeight: fontWeight === undefined ? 400 : fontWeight,
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px',
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
            fontHeight: lineHeigth === undefined ? '133%' : lineHeigth
        })
        let resId = this.addBlockManager(_obj, _obj.id);
        return resId
    }
    addTextLink = (_for) => {
        var id = this.uuidv4();
        var _obj = {
            id: id,
            type: 'text-link',
            childrens: [],
            params: {
                backgroundColor: '#fff',
                background: '#fff',
                paddingTop: _for === undefined ? '12px' : '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                paddingBTop: '0px',
                paddingBBottom: '0px',
                paddingBLeft: '0px',
                paddingBRigth: '0px',
                text: 'Lorem Ipsum',
                color: '#000',
                link: 'https://digitum.nl',
                paddingBottom: _for === undefined ? '12px' : '0px',
                marginTop: '0px',
                marginRight: '0px',
                align: 'center',
                for: _for === undefined ? -99 : _for,
                marginLeft: '0px',
                marginBottom: '0px',
                fontFamily: 'Arial',
                fontSize: '14px',
                fontWeight: 400,
                fontHeight: '133%',
                borderStyle: '',
                borderWidth: '',
                borderColor: '',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
            }
        };
        this.addBlockManager(_obj)
    }

    mainRender = () => {
        if (this.props.arr !== undefined && this.props.arr !== null) {
            var mainElm = [];
            try {
                this.props.arr.filter(x => x.id === '-122');
            }
            catch (e) { }
            var obj = {
                backgroundColor: '#ffffff',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px'
            };
            if (mainElm.length > 0) {
                obj = mainElm[0].params;
            }
            return <div ref={this.sheetBoxRef} style={{
                width: '602px', minHeight: '100%',
                paddingTop: obj.paddingTop,
                paddingBottom: obj.paddingBottom,
                paddingLeft: obj.paddingLeft,
                paddingRight: obj.paddingRight,
                backgroundColor: obj.backgroundColor
            }}>
                {this.props.arr.map(x => {
                    if (x !== undefined && x.params !== undefined) {
                        if (x.params.for !== undefined && x.params.for === -99) {
                            return generateBlock(x);
                        } else {
                            <div style={{ width: '100%', height: '100%', backgroundColor: 'white' }}></div>
                        }
                    }

                })}
            </div>;
        } else {
            return <></>;
        }
    }

    renderAttr = (id, attr) => {
        let arr = [];
        for (var i = 0; i < attr.length; i++) {
            switch (attr[i]) {
                case 140:
                    arr.push(<Ai_text for={id} />);
                    break;
                case 0:
                    arr.push(<DeleteBox for={id} />);
                    break;
                case 1:
                    arr.push(<BackgroundBox for={id} bad={false} />);
                    break;
                case 2:
                    arr.push(<FontBox for={id} aiColor={true} />);
                    break;
                case 3:
                    arr.push(<MarginBox for={id} />);
                    break;
                case 4:
                    arr.push(<PaddingBox for={id} bad={false} />);
                    break;
                case 10:
                    arr.push(<PaddingBox for={id} bad={true} />);
                    break;
                case 5:
                    arr.push(<ImageBox for={id} ai={true} setCurrentPage={this.props.setCurrentPage} />);
                    break;
                case 6:
                    arr.push(<LinkBox for={id} />);
                    break;
                case 7:
                    arr.push(<Border for={id} />);
                    break;
                case 8:
                    arr.push(<AlignBox for={id} />);
                    break;
                case 9:
                    arr.push(<BackgroundBox for={id} bad={true} />);
                    break;
                case 11:
                    arr.push(<DirBox for={id} />);
                    break;
                default:
                    break;
            }
        }
        return arr;
    }
    _toast = (text, status) => {
        toast(text, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: status === 200 ? 'toast-success' : 'toast-error'
        });
    }
    getElm = () => {


        var id = this.props.selectedElm;
        for (var i = 0; i < this.props.arr.length; i++) {

            if (this.props.arr[i].id == id) {
                for (var j = 0; j < this.types.length; j++) {
                    if (this.types[j].obj.type === this.props.arr[i].type) {
                        const new_arr = this.renderAttr(id, this.types[j].obj.attr);
                        this.setState({ attrs: new_arr });
                    }
                }
            }
        }

    }
    nextStep = () => {

        if (this.state.step === 1 && (this.state.input_data.company_name.length < 3 || this.state.input_data.product_name.length < 3)) {

            this._toast('Type your Bank Name and your Card Name', 403);
            return;
        }
        if (this.state.step + 1 === 2) {
            this.props.setArr([]);
            this.createAiStuff();
            this.setState({ loading: true });
        }
        this.setState({ step: this.state.step + 1 })


    }
    componentDidUpdate = (props, state) => {
        if (props.selectedElm != this.props.selectedElm) {
            this.setState({ attrs: [] });
            setTimeout(() => this.getElm(), 50);
        }
    }
    setTestArr = () => {
        var data = JSON.parse(test_data[0].data);
        this.props.setArr(data);
    }
    componentDidMount() {
        //  const height = this.divElement.clientHeight;
        //  this.setState({ leftMenuControlsHeight: height + 10 });
        //  this.setTestArr();
        if (this.state.step >= 2) {
            document.addEventListener('click', this.handleClickOutside);
        }

        if (this.props.arr.length > 0 && this.props.arr.filter(x => x.id === '-122').length === 0) {
            this.addMainBlock();
            this.props.putElm({ id: -99 });
            this.getElm();
            // var _this = this;
            // setTimeout(() => _this.props.putElm( {id:'-122'}), 150);  

        }
        else if (this.props.arr.length === 0) {
            this.addMainBlock();
            this.props.putElm({ id: -99 });
            this.getElm();
        }
        else {
            this.props.putElm(-99);
            this.getElm();
        }
    }
    addMainBlock = () => {

        var _obj = {
            id: '-122',
            childrens: [],
            type: "main-block",
            params: {
                paddingTop: '0',
                paddingLeft: '0',
                paddingRight: '0',
                paddingBottom: '0',
                backgroundColor: '#ffffff',
                for: -122,

            }
        }
        this.addBlockManager(_obj);

    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside)
    }

    handleClickOutside = (e) => {
        if (this.state.step === 2) {
            if (!this.blockTypesBoxRef.current.contains(e.target) &&
                !this.sheetBoxRef.current.contains(e.target) &&
                !this.attrBoxRef.current.contains(e.target) && this.props.selectedElm !== '-122') {
                this.props.putElm(-99);
            }
        }

        /* if (!this.menuRef.current.contains(e.target)) {
             this.setState({ open_menu: false })
         }*/
    }
    sendMail = () => {
        if (this.state.receiver.length === 0 || this.state.subject.length === 0) {
            return;
        }
        var json = {
            token: this.props.token,
            body: getHtmlRaw(this.props.arr),
            subject: this.state.subject,
            to: this.state.receiver,
            preHeader: this.state.subject
        };
        fetch('SendTestMail', {
            //        fetch('https://localhost:44366/SendMail', {
            method: "POST",
            body: JSON.stringify(json),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(x => this._toast(x.status === 200 ? "Sended" : "Server Error", x.status));
    }
    aiSubject = () => {
        this.setState({ loadingSubject: true });
        var json = {
            token: this.props.token,
            words: 20,
            prompt: getHtmlRaw(this.props.arr),
            tone: 'buisness'
        };
        fetch('CreateGPTSubject', {
            method: "POST",
            body: JSON.stringify(json),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(x => x.json()).then(x => this.setState({ subject: x.text.replace('"', '').replace('"', ''), loadingSubject: false }));
    }
    getHtml = () => {
        const html = getHtmlRaw(this.props.arr);
        const element = document.createElement("a");
        const file = new Blob([html], { type: 'text/html' });
        element.href = URL.createObjectURL(file);
        element.download = "index.html";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        this.setState({ open_menu: false });
    }
    saveEmail = (do_toast) => {
        var json = {
            email_id: this.props.email_id,
            token: this.props.token,
            data: JSON.stringify(this.props.arr)
        };
        fetch('UpdateEmail', {
            method: "POST",
            body: JSON.stringify(json),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(x => {
            this.setState({ open_menu: false });
            if (do_toast === undefined) {
                this._toast("Success", x.status);
            }
        });
    }
    removeEmail = () => {
        var json = {
            token: this.props.token,
            id: this.props.email_id
        };
        fetch('RemoveEmail', {
            method: "POST",
            body: JSON.stringify(json),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(x => this.props.setCurrentPage('campaign_details'));
    }
    // newEmail = () => {
    //     var json = {
    //         campaignId: this.props.campaignId,
    //         token: this.props.token,
    //         data: '[]'
    //     };
    //     fetch('UploadEmail', {
    //         method: "POST",
    //         body: JSON.stringify(json),
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         }
    //     }).then(x => x.json()).then(x => {
    //         this.props.setArr([]);
    //         this.props.setEmailId(x.emailId);
    //     });
    // }
    getRotate = (i) => {
        if (this.state.selectedBlock === i) {
            return 'rotate(90deg)';
        } else {
            return 'rotate(0deg)';
        }
    }
    getAttrs = (attrs) => {
        if (attrs.length === 0) {
            //console.log(this.props.selectedElm);
        }
        if (attrs.length === 0 && this.props.selectedElm === undefined) {
            this.props.putElm({ id: '-122' });
        } else {
            return this.state.attrs.map(x => x);
        }
    }
    getProgressBar = (value) => {
        var base = 5;
        // base = base * (2 + 1) * 3;
        // 0 - 5, 1 - 50, 2 - 95, 3 - 140
        if (value > 0) {
            base = base * 10 * value - 5;
        }

        return base.toString() + "px";
    }
    setLetterType = (type) => {


        this.setState({
            step: 1,
            letterType: type
        });
        this.setInputdata(type);
        setTimeout(() => { document.getElementsByClassName('letter-block')[0].scrollTop = 0; }, 50);


    }
    getCategoryName = (type) => {
        switch (type) {
            case 0:
                return "Welcome";
            case 1:
                return "POS activation";
            case 2:
                return "e-Com activation";
            case 3:
                return "Cross-Border Planning&Booking Activation";
            case 4:
                return "Cross-Border At destination Activation";
            default:
                return "Welcome";
        }
    }
    render() {
        return (<div className="App" style={{ overflowY: 'scroll    ', backgroundColor: 'white' }}>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

            <div style={{ display: 'none' }}>
                <button onClick={() => this.props.setArr([])}>clear</button>
                <button onClick={() => this.decodeAiStructure()}>add</button>
                <textarea onInput={(e) => this.setState({ prompt: e.target.value })}></textarea>
                <button onClick={() => this.createAiStuff()}>create</button>
                <button onClick={() => this.openPreviewModal()} >Preview</button>
                <button onClick={() => console.log((this.props.arr))}>copy to console</button>
                {this.state.openedPreviewModal && <PreviewModal close={this.closePreviewModal} />}
            </div>
            <div style={{ height: 'calc(100vh)', display: 'flex', justifyContent: 'space-between' }}>

                <div ref={this.state.step >= 2 ? this.blockTypesBoxRef : null} className='constructor-side-block ai-side-left' style={{ cursor: 'pointer', borderRight: '1px solid #EFF1F133', width: '380px', backgroundColor: '#EFF1F1', position: 'absolute', height: '100%', overflowY: 'scroll' }}>
                    <img onClick={() => this.props.setCurrentPage('dashboard')} src={icons.logo_adbot_small} />

                    <table className='ai-progress-table'>
                        <tr>
                            <td style={{ color: 'white' }}>1<div style={{ width: '4px', backgroundColor: '#C1D0CE', height: 'calc(300% + 24px + 24px + 24px)', position: 'absolute', zIndex: -1, marginLeft: '10px', marginTop: '5x' }}></div>
                                <div style={{ width: '4px', backgroundColor: '#34B9A0', height: this.getProgressBar(this.state.step), position: 'absolute', zIndex: -1, marginLeft: '10px' }}></div>
                            </td>
                            <td style={{ color: '#34B9A0' }}>Category</td>
                        </tr>
                        <tr>
                            <td style={{ color: this.state.step >= 1 ? 'white' : '', backgroundColor: this.state.step >= 1 ? '' : '#C1D0CE' }}>2</td>
                            <td style={{ color: this.state.step >= 1 ? '#34B9A0' : '' }}>Input</td>
                        </tr>
                        <tr>
                            <td style={{ color: this.state.step >= 2 ? 'white' : '', backgroundColor: this.state.step >= 2 ? '' : '#C1D0CE' }}>3</td>
                            <td style={{ color: this.state.step >= 2 ? '#34B9A0' : '' }}>Edit</td>
                        </tr>
                        <tr>
                            <td style={{ color: this.state.step >= 3 ? 'white' : '', backgroundColor: this.state.step >= 3 ? '' : '#C1D0CE' }}>4</td>
                            <td style={{ color: this.state.step >= 3 ? '#34B9A0' : '' }}>Preview</td>
                        </tr>
                    </table>
                </div>
                <div className='letter-block' style={{ backgroundColor: 'white', width: this.state.step === 1 ? '669px' : '657px', maxHeight: 'calc(100vh - 50px)', marginLeft: 'calc(50% - 380px + 55px)', overflowY: this.state.step === 1 ? 'scroll' : '', paddingRight: this.state.step === 1 ? '12px' : '' }}>
                    <div className='ai-category-main' style={{ display: this.state.step === 0 ? 'block' : 'none' }}>
                        <h1>Category</h1>
                        <hr />
                        <div className='ai-select-category'>
                            <p onClick={() => this.setState({ s_0_status: this.s_0_status === 0 ? -1 : 0 })}
                                style={{ color: this.state.s_0_status === 0 ? '#34B9A0' : '' }}>Welcome
                                <img className={this.state.s_0_status === 0 ? 'img_selected_rotate' : ''} src='data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDQuNDE1TDEwLjU4NSAzTDYgNy41ODVMMS40MTUgM0wtNi4xODUxNmUtMDggNC40MTVMNiAxMC40MTVMMTIgNC40MTVaIiBmaWxsPSIjMjMzMTJGIi8+Cjwvc3ZnPgo=' />
                            </p>
                            <Fade>
                                <ul style={{ display: this.state.s_0_status === 0 ? '' : 'none' }}>
                                    <li onClick={() => this.setLetterType(0)}>
                                        Welcome
                                    </li>
                                </ul>
                            </Fade>

                        </div>
                        <div className='ai-select-category'>
                            <p onClick={() => this.setState({ s_0_status: this.s_0_status === 1 ? -1 : 1 })}
                                style={{ color: this.state.s_0_status === 1 ? '#34B9A0' : '' }}
                            >Activation
                                <img className={this.state.s_0_status === 1 ? 'img_selected_rotate' : ''} src='data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDQuNDE1TDEwLjU4NSAzTDYgNy41ODVMMS40MTUgM0wtNi4xODUxNmUtMDggNC40MTVMNiAxMC40MTVMMTIgNC40MTVaIiBmaWxsPSIjMjMzMTJGIi8+Cjwvc3ZnPgo=' />

                            </p>

                            <Fade>
                                <ul style={{ display: this.state.s_0_status === 1 ? '' : 'none' }}>
                                    <li onClick={() => this.setLetterType(1)}>
                                        POS activation
                                    </li>
                                    <li onClick={() => this.setLetterType(2)}>
                                        e-Com activation
                                    </li>
                                    <li onClick={() => this.setLetterType(3)}>
                                        Cross-Border Planning&Booking Activation
                                    </li>
                                    <li onClick={() => this.setLetterType(4)}>
                                        Cross-Border At destination Activation
                                    </li>

                                </ul>
                            </Fade>

                        </div>
                    </div>
                    <div style={{ display: this.state.step === 1 ? '' : 'none', padding: '0px 16px' }}>

                        <div className='input-header'>
                            <h3>Input</h3>
                            <hr />

                            <p>Create an emailer with the theme {this.getCategoryName(this.state.letterType)}</p>
                            <br />
                        </div>
                        <div style={{ display: this.state.input_data.company_name === '-1' ? 'none' : '' }} className='input-box'>
                            <p>Bank name</p>
                            <input
                                style={{}}
                                value={this.state.input_data.company_name} onInput={(e) => {
                                    var data = this.state.input_data;
                                    data.company_name = e.target.value;
                                    this.setState({ input_data: data });
                                }} />
                        </div>
                        <div style={{ display: this.state.input_data.product_name === '-1' ? 'none' : '' }} className='input-box'>
                            <p>Card name</p>
                            <input
                                style={{}}
                                value={this.state.input_data.product_name} onInput={(e) => {
                                    var data = this.state.input_data;
                                    data.product_name = e.target.value;
                                    this.setState({ input_data: data });
                                }} />
                        </div>
                        <div style={{ display: this.state.input_data.brif_zero === '-1' ? 'none' : '' }} className='input-box'>
                            <p>{this.state.letterType === 0 ? 'How to activate the card' : 'Brief terms of the promotion'}</p>
                            <textarea maxLength={2000}
                                placeholder={this.state.letterType === 0 ? 'Describe instructions how to activate the card' : 'Describe brief terms of the promotion'}
                                style={{}}
                                value={this.state.input_data.brif_zero} onInput={(e) => {
                                    var data = this.state.input_data;
                                    data.brif_zero = e.target.value;
                                    this.setState({ input_data: data });
                                    e.target.parentNode.getElementsByTagName('SPAN')[0].innerText = `${e.target.value.length} / 2000`;
                                }} ></textarea>
                            <span>0 / 2000</span>
                        </div>
                        <div style={{ display: this.state.input_data.key_benefits === '-1' ? 'none' : '' }} className='input-box'>
                            <p>Key benefits</p>
                            <textarea maxLength={2000}
                                placeholder='Indicate the key benefits of the card'
                                style={{}}
                                value={this.state.input_data.key_benefits} onInput={(e) => {
                                    var data = this.state.input_data;
                                    data.key_benefits = e.target.value;
                                    this.setState({ input_data: data });
                                    e.target.parentNode.getElementsByTagName('SPAN')[0].innerText = `${e.target.value.length} / 2000`;
                                }} ></textarea>
                            <span>0 / 2000</span>
                        </div>
                        <div style={{ display: this.state.input_data.brif_one === '-1' ? 'none' : '' }} className='input-box'>
                            <p>Basic features</p>
                            <textarea maxLength={2000}
                                placeholder='Indicate the basic features of the card'
                                style={{}}
                                value={this.state.input_data.brif_one} onInput={(e) => {
                                    var data = this.state.input_data;
                                    data.brif_one = e.target.value;
                                    this.setState({ input_data: data });
                                    e.target.parentNode.getElementsByTagName('SPAN')[0].innerText = `${e.target.value.length} / 2000`;
                                }} ></textarea>
                            <span>0 / 2000</span>
                        </div>
                        <div style={{ display: this.state.input_data.brif_two === '-1' ? 'none' : '' }} className='input-box'>
                            <p>Available offers</p>
                            <textarea maxLength={2000}
                                placeholder='Indicate available offers of the card'
                                style={{}}
                                value={this.state.input_data.brif_two} onInput={(e) => {
                                    var data = this.state.input_data;
                                    data.brif_two = e.target.value;
                                    this.setState({ input_data: data });
                                    e.target.parentNode.getElementsByTagName('SPAN')[0].innerText = `${e.target.value.length} / 2000`;
                                }} ></textarea>
                            <span>0 / 2000</span>
                        </div>
                        <div style={{ display: this.state.input_data.brif_extra === '-1' ? 'none' : '' }} className='input-box'>
                            <p>Additional requirements</p>
                            <textarea maxLength={2000}
                                placeholder='Specify additional requirements for the mailer'
                                style={{}}
                                value={this.state.input_data.brif_extra} onInput={(e) => {
                                    var data = this.state.input_data;
                                    data.brif_extra = e.target.value;
                                    this.setState({ input_data: data });
                                    e.target.parentNode.getElementsByTagName('SPAN')[0].innerText = `${e.target.value.length} / 2000`;
                                }} ></textarea>
                            <span>0 / 2000</span>
                        </div>
                    </div>
                    <div id="loading_stuff" Style="position: absolute; z-index: 1; background: transparent; top: 0px; width: calc(100vw - 380px);left: 380px;height: 100%;display: flex;justify-content: center;align-items: center;" style={{ display: this.state.loading ? 'flex' : 'none' }}>
                        <img className='loading_ai' src="https://adbotest.digitum.nl/static/media/adbot_big.svg" /></div>

                    <div style={{ minHeight: '600px', width: '700px', marginBottom: '60px', borderRadius: '8px', display: this.state.step === 2 ? '' : 'none', filter: this.state.loading ? 'blur(8px)' : '' }}>
                        <div className='input-header'>
                            <h3>{this.state.step === 2 ? 'Edit' : 'Preview'}</h3>
                            <hr />
                        </div>
                        <div>
                            <div style={{ display: this.state.step === 3 ? 'flex' : 'none', marginBottom: '8px' }}>

                                <input style={{ background: '#EFF1F1', border: 'none', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px', padding: '8px 16px', color: '#14201E', width: '526px', height: '40px', outline: 'none' }} />
                                <p style={{ background: '#34B9A0', padding: '15px 13px', fontFamily: 'Montserrat', color: 'white', borderTopRightRadius: '4px', borderBottomRightRadius: '4px', height: '40px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>

                                    Send
                                </p>
                            </div>
                            <div style={{ display: 'flex', height: '40px', marginBottom: '32px' }}>
                                <p onClick={() => this.aiSubject()} style={{ cursor: 'pointer', width: '131px', justifyContent: 'space-evenly', background: '#34B9A0', padding: '15px 13px', fontFamily: 'Montserrat', color: 'white', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px', height: '40px', display: 'flex', alignItems: 'center' }}>
                                    <img style={{ filter: this.state.loadingSubject ? 'invert(1)' : '' }} src={this.state.loadingSubject ? icons.m_loading : icons.sparkles} />
                                    Subject
                                </p>
                                <input value={this.state.subject} onInput={(e) => this.setState({ subject: e.target.value })} style={{ background: '#EFF1F1', border: 'none', borderTopRightRadius: '8px', padding: '8px 16px', borderBottomRightRadius: '8px', color: '#14201E', width: '493px', height: '40px', outline: 'none' }} />
                            </div>

                        </div>
                        <Html style={{ height: '100%', overflowX: 'visible', overflowY: 'scroll', paddingBottom: '72px', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            {this.mainRender()}
                        </Html>

                    </div>
                    <div style={{ minHeight: '600px', width: '700px', marginBottom: '60px', borderRadius: '8px', display: this.state.step === 3 ? '' : 'none', filter: this.state.loading ? 'blur(8px)' : '' }}>
                        <div className='input-header'>
                            <h3>{this.state.step === 2 ? 'Edit' : 'Preview'}</h3>
                            <hr />
                        </div>
                        <div>
                            <div style={{ display: this.state.step === 3 ? 'flex' : 'none', marginBottom: '8px' }}>

                                <input value={this.state.receiver} onInput={(e) => this.setState({ receiver: e.target.value })} style={{ background: '#EFF1F1', border: 'none', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px', padding: '8px 16px', color: '#14201E', width: '526px', height: '40px', outline: 'none' }} />
                                <p onClick={() => this.sendMail()} style={{ background: '#34B9A0', padding: '15px 13px', fontFamily: 'Montserrat', color: 'white', borderTopRightRadius: '4px', borderBottomRightRadius: '4px', height: '40px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>

                                    Send
                                </p>
                            </div>
                            <div style={{ display: 'flex', height: '40px', marginBottom: '32px' }}>
                                <p onClick={() => this.aiSubject()} style={{ cursor: 'pointer', width: '131px', justifyContent: 'space-evenly', background: '#34B9A0', padding: '15px 13px', fontFamily: 'Montserrat', color: 'white', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px', height: '40px', display: 'flex', alignItems: 'center' }}>
                                    <img style={{ filter: this.state.loadingSubject ? 'invert(1)' : '' }} src={this.state.loadingSubject ? icons.m_loading : icons.sparkles} />
                                    Subject
                                </p>
                                <input value={this.state.subject} onInput={(e) => this.setState({ subject: e.target.value })} style={{ background: '#EFF1F1', border: 'none', borderTopRightRadius: '8px', padding: '8px 16px', borderBottomRightRadius: '8px', color: '#14201E', width: '493px', height: '40px', outline: 'none' }} />
                            </div>

                        </div>
                        {this.state.step === 3 ? <iframe
                            className='previewModal-iframe'
                            width={620}
                            style={{ border: '1px solid rgba(0, 0, 0, 0.1)', height: '100vh' }}
                            srcDoc={getHtmlRaw(this.props.arr)}
                        /> : <></>}




                    </div>
                    <div className='ai-control' style={{ display: this.state.step > 0 ? (this.state.loading ? 'none' : '') : 'none' }}>
                        <button className='ai-control-back' onClick={() => this.setState({ step: this.state.step - 1 })}>Back</button>
                        <button style={{ display: this.state.step !== 3 ? 'none' : '' }} className='ai-control-next' onClick={() => this.getHtml()}>Download HTML</button>
                        <button style={{ display: this.state.step !== 3 ? 'none' : '' }} className='ai-control-next' onClick={() => this.saveEmail()}>Save</button>
                        <button style={{ display: this.state.step >= 3 ? 'none' : '' }} className='ai-control-next' onClick={() => this.nextStep()}>Next</button>
                    </div>
                </div>
                <div ref={this.attrBoxRef} className='constructor-side-block ai-side-block'
                    style={{ position: 'absolute', height: '100%', right: '0', borderLeft: '1px solid rgba(239, 241, 241, 0.2)', width: '380px', filter: this.state.loading ? 'blur(8px)' : '', backgroundColor: this.state.step === 2 ? '#EFF1F1' : 'white', overflowY: 'scroll' }}
                >
                    <span style={{ display: this.state.step === 2 ? '' : 'none' }}>{this.getAttrs(this.state.attrs)}</span>
                </div>
            </div>
        </div>)
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setArr: setArr, setEmailId: setEmailId, putElm: putElm, setAiImages: setAiImages }, dispatch);
}
const mapStateToProps = state => {

    return {
        arr: state.arr,
        selectedElm: state.selectedElm,
        email_id: state.email_id,
        token: state.token,

        campaignId: state.campaignId
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(AIConstructor);