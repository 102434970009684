import React, { Component } from 'react';

import { connect } from 'react-redux';
import { putElm, setArr } from '../../../actions/actions';
import Menu from '../../../components/Menu';

import { bindActionCreators } from 'redux';
import generateBlock from './generateBlock';

class ListBlock extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    getImg = () => {

        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "image") {
                return generateBlock(arr[i]);
            }
        }
    }
    getText = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "text") {
                return generateBlock(arr[i]);
            }
        }
    }
    getMarginTop = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.marginTop;
            }
        }
    }
    getMarginLeft = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.marginLeft;
            }
        }
    }
    getMarginRight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.marginRight;
            }
        }
    }
    getMarginBottom = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.marginBottom;
            }
        }
    }
    getPaddingTop = (override) => {
        if (!this.checkIfOffers().result || override === true) {
            var arr = this.props.arr;
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === this.props.id) {
                    return arr[i].params.paddingTop;
                }
            }
        }
        return '0';
    }
    getPaddingLeft = () => {
        if (!this.checkIfOffers().result) {
            var arr = this.props.arr;
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === this.props.id) {
                    return arr[i].params.paddingLeft;
                }
            }
        }
        return '0';
    }
    getPaddingRight = () => {
        if (!this.checkIfOffers().result) {
            var arr = this.props.arr;
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === this.props.id) {
                    return arr[i].params.paddingRight;
                }
            }
        }
        return '0';
    }
    getPaddingBottom = (override) => {
        if (!this.checkIfOffers().result || override === true) {
            var arr = this.props.arr;
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === this.props.id) {
                    return arr[i].params.paddingBottom;
                }
            }
        }
        return '0';
    }
    getBackgroundColor = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.backgroundColor;
            }
        }
    }

    checkIfOffers = () => {

        var arr = this.props.arr;

        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                if (arr[i].params.for !== -99) {
                    for (var j = 0; j < arr.length; j++) {
                        if (arr[j].id === arr[i].params.for && arr[j].type.indexOf('ai-offers') !== -1) {
                            return { result: true, type: arr[j].type, id: arr[j].id };
                        }
                    }
                } else {
                    return false;
                }
            }
        }
        return false;
    }

    checkIfCanInteract = () => {
        return this.props.arr.filter(item => item.id === this.props.id)[0].params.for === -99;
    }

    editBlock = () => {
        this.props.putElm({ id: this.props.id, type: this.props.type })
    }

    deleteBlock = () => {
        const deletedBlock = this.props.arr.filter(item => item.id === this.props.id);
        if (deletedBlock[0].params.for !== -99) return;
        this.props.setArr(this.props.arr.filter(item => item.id !== this.props.id));
    }

    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    copyBlock = () => {
        let id = this.uuidv4();
        const block = this.props.arr.filter(item => item.id === this.props.id);
        const blockChildrens = this.props.arr.filter(item => item.params.for === block[0].id);
        const modifiedblockChildrens = blockChildrens.map(item => {
            return { ...item, id: this.uuidv4(), params: { ...item.params, for: id } }
        })
        let childrens = [];
        modifiedblockChildrens.forEach(item => childrens.push(item.id))
        const modifiedBlock = structuredClone(block.map(item => {
            return { ...item, id: id, childrens: [...childrens] }
        }))
        const res = [...modifiedblockChildrens, ...modifiedBlock];
        this.props.arr.push(...res);
        var arr = Array.prototype.concat(this.props.arr);
        this.props.setArr(arr);

    }

    elemMove = (direction) => {
        let arr = this.props.arr.filter(elem => elem.params.for === -99);
        let arrWithout99 = this.props.arr.filter(elem => elem.params.for !== -99);
        let selectedElemIndex = 0;
        let selectedElem = arr.find((elem, index) => {
            selectedElemIndex = index;
            return elem.id === this.props.id
        });
        let res = [];
        if (direction === 'up') {
            if (selectedElemIndex === 0) return;
            const arrCopy = [...arr];
            let element = arrCopy[selectedElemIndex];
            arrCopy.splice(selectedElemIndex, 1);
            arrCopy.splice(selectedElemIndex - 1, 0, element);
            res = arrCopy;
        }
        if (direction === 'down') {
            if (selectedElemIndex === this.props.arr.length - 1) return;
            const arrCopy = [...arr];
            let element = arrCopy[selectedElemIndex];
            arrCopy.splice(selectedElemIndex, 1);
            arrCopy.splice(selectedElemIndex + 1, 0, element);
            res = arrCopy;
        }
        this.props.setArr(res.concat(arrWithout99));
    }

    componentDidMount = () => {
        document.querySelectorAll('#moved-block')[0].classList.add('menu-moveBottom')
    }

    componentWillUnmount = () => {
        document.querySelectorAll('#moved-block')[0].classList.remove('menu-moveBottom')
    }
    getborderTopLeftRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.borderTopLeftRadius;
            }
        }
    }
    getborderBottomLeftRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.borderBottomLeftRadius;
            }
        }
    }
    getborderTopRightRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.borderTopRightRadius;
            }
        }
    }
    getborderBottomRightRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.borderBottomRightRadius;
            }
        }
    }
    getIWidth = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "image") {
                return arr[i].params.width;
            }
        }
    }
    getIBackground = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "image") {
                return arr[i].params.backgroundColor;
            }
        }
    }
    getTBackground = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "text") {
                return arr[i].params.backgroundColor;
            }
        }
    }
    getAlign = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.align;
            }
        }
    }
    getIAlign = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "image") {
                return arr[i].params.align;
            }
        }
    }
    getTAlign = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "text") {
                return arr[i].params.align;
            }
        }
    }
    getDir = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {

                return arr[i].params.dir;
            }
        }
    }
    getFor = () => {
        var arr = this.props.arr;
        let _for = -99;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                _for = arr[i].params.for;

            }
        }
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === _for) {
                return arr[i].type === 'ai-list-2';
            }
        }
    }
    getTrueWidth = () => {

        var width = this.getFor() ? '301px' : '602px';
        var offersResult = this.checkIfOffers();
        if (offersResult.result === false || offersResult.type === 'ai-offers-3' || offersResult.type === 'ai-offers-1') {
            var arr = this.props.arr;
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === '-122' || offersResult.result === false) {

                    var params = arr[i].params;
                    var pR = parseInt(params.paddingRight);
                    var pL = parseInt(params.paddingLeft);

                    width = (this.getFor() ? 301 : 602) - pR - pL;
                    width = width.toString() + 'px';
                    break;
                }
                else if (offersResult.type === 'ai-offers-1' && arr[i].id === offersResult.id) {
                    var params = arr[i].params;
                    var pR = parseInt(params.paddingRight);
                    var pL = parseInt(params.paddingLeft);

                    width = 602 - pR - pL;
                    width = width.toString() + 'px';
                    break;
                }
                else if (arr[i].type === offersResult.type) {
                    var params = arr[i].params;
                    var pR = parseInt(params.paddingRight);
                    var pL = parseInt(params.paddingLeft);

                    width = (300) - pR - pL;
                    width = width.toString() + 'px';
                    break;
                }
            }
            return width;
        }
        else {
            return '593px';
        }
    }
    render() {
        return (
            <table data-content='List' id='moved-block' className='higlighted__parentElem' width={this.getTrueWidth()} cellspacing="0" align={this.getAlign()} cellpadding="0" style={{
                maxWidth: this.getTrueWidth(), width: this.getTrueWidth(),
                border: this.props.selectedElm === this.props.id ? '2px solid #34b9a0' : '',
                borderTopLeftRadius: this.getborderTopLeftRadius(),
                borderBottomLeftRadius: this.getborderBottomLeftRadius(),
                borderTopRightRadius: this.getborderTopRightRadius(),
                borderBottomRightRadius: this.getborderBottomRightRadius(),
                paddingBottom: this.checkIfOffers().result ? this.getPaddingBottom(true) : '0px',
                paddingTop: this.checkIfOffers().result ? this.getPaddingTop(true) : '0px',

                backgroundColor: this.getBackgroundColor()
            }} onClick={(e) => e.target.tagName === "TD" ? this.props.putElm({ id: this.props.id, type: this.props.type }) : null}>
                <tr style={{
                    display: 'block', marginTop: this.getMarginTop(), marginLeft: this.getMarginLeft(), marginRight: this.getMarginRight(),
                    marginBottom: this.getMarginBottom()
                }}>
                    {this.getDir() ? <>

                        <td align={this.getTAlign()} style={{
                            borderTopRightRadius: this.getborderTopRightRadius(),
                            width: '100%',
                            borderBottomRightRadius: this.getborderBottomRightRadius(),
                            backgroundColor: this.getTBackground(), paddingTop: this.checkIfOffers().result ? '0px' : this.getPaddingTop(), paddingRight: this.getPaddingRight(),
                            paddingBottom: this.checkIfOffers().result ? '0px' : this.getPaddingBottom(),
                        }}>{this.getText()}</td>
                        <td align={this.getIAlign()} style={{
                            borderTopLeftRadius: this.getborderTopLeftRadius(),
                            borderBottomLeftRadius: this.getborderBottomLeftRadius(),
                            verticalAlign: 'top', paddingTop: this.checkIfOffers().result ? '0px' : this.getPaddingTop(), paddingLeft: this.getPaddingLeft(),
                            paddingBottom: this.checkIfOffers().result ? '0px' : this.getPaddingBottom(),
                            width: this.getIWidth(),
                            backgroundColor: this.getIBackground()
                        }}>{this.getImg()}</td>
                    </> : <><td align={this.getIAlign()} style={{
                        borderTopLeftRadius: this.getborderTopLeftRadius(),
                        borderBottomLeftRadius: this.getborderBottomLeftRadius(),
                        verticalAlign: 'top', paddingTop: this.checkIfOffers().result ? '0px' : this.getPaddingTop(), paddingLeft: this.getPaddingLeft(),
                        paddingBottom: this.checkIfOffers().result ? '0px' : this.getPaddingBottom(),
                        width: this.getIWidth(),
                        backgroundColor: this.getIBackground()
                    }}>{this.getImg()}</td>
                        <td align={this.getTAlign()} style={{
                            borderTopRightRadius: this.getborderTopRightRadius(),
                            width: '100%',
                            borderBottomRightRadius: this.getborderBottomRightRadius(),
                            backgroundColor: this.getTBackground(), paddingTop: this.checkIfOffers().result ? '0px' : this.getPaddingTop(), paddingRight: this.getPaddingRight(),
                            paddingBottom: this.checkIfOffers().result ? '0px' : this.getPaddingBottom(),
                        }}>{this.getText()}</td></>}

                </tr>
                <Menu
                    parent
                    edit={this.editBlock}
                    canInteract={this.checkIfCanInteract()}
                    delete={this.deleteBlock}
                    copy={this.copyBlock}
                    moveUp={() => this.elemMove('up')}
                    moveDown={() => this.elemMove('down')}
                />
            </table>
        );
    }

}
/*

<Section data-content='List' id='moved-block' className='higlighted__parentElem' style={{
                paddingTop: this.getPaddingTop(), paddingBottom: this.getPaddingBottom(),
                border: this.props.selectedElm === this.props.id ? '2px solid #34b9a0' : '',
                paddingLeft: this.getPaddingLeft(), paddingRight: this.getPaddingRight(),
                borderTopLeftRadius: this.getborderTopLeftRadius(),
                borderBottomLeftRadius: this.getborderBottomLeftRadius(),
                borderTopRightRadius: this.getborderTopRightRadius(),
                borderBottomRightRadius: this.getborderBottomRightRadius(),
                backgroundColor: this.getBackgroundColor(),
                marginTop: this.getMarginTop(), marginBottom: this.getMarginBottom(), marginLeft: this.getMarginLeft(),
                marginRight: this.getMarginRight(),
            }} onClick={(e) => e.target.tagName === "TD" ? this.props.putElm({ id: this.props.id, type: this.props.type }) : null}>
                <Column style={{ verticalAlign: 'top' }}>{this.getImg()}</Column>
                <Column>{this.getText()}</Column>
                <Menu
                    parent
                    edit={this.editBlock}
                    canInteract={this.checkIfCanInteract()}
                    delete={this.deleteBlock}
                    copy={this.copyBlock}
                    moveUp={() => this.elemMove('up')}
                    moveDown={() => this.elemMove('down')}
                />
            </Section>
*/

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ putElm: putElm, setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr,
        selectedElm: state.selectedElm
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(ListBlock);