import { getImgBlock } from "./imageBlock";
import { getTextBlock } from "./textBlock";
import { Column } from '@react-email/column';
import { Section } from '@react-email/section';

export const getListBlock = (img, text, params, main, offers, double, type) => {
    var params_i = img.params;
    var params_t = text.params;
    var rtl = false;
    if (params.dir !== undefined && params.dir !== null) {
        rtl = params.dir;
    }

    var txt_padding = '0px';
    var height = 'auto';
    var width = '601px';

    if (main !== undefined && main !== null) {
        var _params = main;
        var pR = parseInt(_params.paddingRight);
        var pL = parseInt(_params.paddingLeft);
        //  console.error(pR);
        var _width = (double === undefined ? 601 : 301) - pR - pL; // 350
        width = _width.toString() + 'px';

    }
    var txt_w = 'auto';
    if (type !== undefined) {

        txt_w = (parseInt(width) - parseInt(params_i.width) - parseInt(params_t.paddingRight) * 3).toString() + 'px';
        if (type !== true && type !== false) {
            width = type;

            //alert(width);
        } else if (type === false) {
            txt_w = parseInt(width) - parseInt(params_i.width);
        }

    }
    //console.error(parseInt(width) - parseInt(params_i.width));
    if (offers !== undefined) {
        var img_d = document.createElement('IMG');
        var p_d = document.createElement('P');
        // p_d.setAttribute('style', 'line-height: '+ pra);

        p_d.style.fontSize = params_t.fontSize;
        p_d.style.lineHeight = params_t.fontHeight;
        if (offers === false) {
            p_d.style.maxWidth = txt_w.toString() + 'px';
        } else {
            p_d.style.maxWidth = '361px';
        }

        p_d.innerText = params_t.text;
        document.body.appendChild(p_d);

        var txt_h = p_d.offsetHeight;


        img_d.src = params_i.src;
        img_d.setAttribute('style', 'position: absolute;z-index: -9999;');
        img_d.style.width = params_i.width;
        document.body.appendChild(img_d);
        height = img_d.offsetHeight.toString() + 'px';

        txt_padding = (((img_d.offsetHeight / 3) - (txt_h > 22 ? (txt_h / 2 - parseInt(params_t.fontSize)) : 0)) + parseInt(params_t.paddingTop));
        if (offers === false) {
            //  txt_padding = txt_padding / 2;
            txt_padding = txt_padding - 10 - (p_d.offsetHeight > 50 ? p_d.offsetHeight / 10 : 0);
            console.error(txt_padding + ' : ' + params_t.text + ' : ' + p_d.offsetHeight);
        }
        txt_padding = txt_padding.toString() + 'px';
        document.body.removeChild(img_d);
        document.body.removeChild(p_d);
        //  console.log(p_d);
    }


   

    // console.error(txt_w);
    return <table width={parseInt(width)} cellspacing="0" align={params.align} cellpadding="0" style={{
        maxWidth: width, width: width,
        borderTopLeftRadius: params.borderTopLeftRadius,
        borderBottomLeftRadius: params.borderBottomLeftRadius,
        borderTopRightRadius: params.borderTopRightRadius,
        borderBottomRightRadius: params.borderBottomRightRadius,
        paddingBottom: offers === undefined ? params.paddingBottom : '0px',
        paddingTop: offers === undefined ? params.paddingTop : '0px',
        marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom, backgroundColor: params.backgroundColor
    }}>
        <tr >
            {rtl ? <>
                <td align={params_t.align} style={{
                    width: '100%',
                    borderTopRightRadius: params.borderTopRightRadius,
                    borderBottomRightRadius: params.borderBottomRightRadius,
                    backgroundColor: params_t.backgroundColor, paddingRight: offers === undefined ? params.paddingRight : '0px',

                }}>{getTextBlock(params_t)}</td>
                <td align={params_i.align} style={{
                    borderTopLeftRadius: params.borderTopLeftRadius,
                    borderBottomLeftRadius: params.borderBottomLeftRadius,
                    verticalAlign: 'top', paddingLeft: offers === undefined ? params.paddingLeft : '0px',

                    width: params_i.width,
                    backgroundColor: params_i.backgroundColor
                }}>{getImgBlock(params_i)}</td>
            </> : <> <td align={params_i.align} style={{
                borderTopLeftRadius: params.borderTopLeftRadius,
                borderBottomLeftRadius: params.borderBottomLeftRadius,
                verticalAlign: 'top', paddingLeft: offers === undefined ? params.paddingLeft : '0px',

                width: params_i.width,
                backgroundColor: params_i.backgroundColor
            }}>{getImgBlock(params_i)}</td>
                <td align={params_t.align} style={{
                    width: '100%',
                    //    paddingTop: offers === undefined ? '' : params.paddingTop,
                    //   msoPaddingTopAlt: offers === undefined ? '' : '5px',
                    //  msoPaddingBottomAlt: offers === undefined ? '' : '5px',
                    borderTopRightRadius: params.borderTopRightRadius,
                    borderBottomRightRadius: params.borderBottomRightRadius,
                    backgroundColor: params_t.backgroundColor, paddingRight: offers === undefined ? params.paddingRight : '0px',

                }}>{getTextBlock(params_t, undefined, undefined, undefined, txt_padding, txt_w)}</td></>}

        </tr>

    </table>;
}