import React, { Component } from 'react';
import { connect } from 'react-redux';
import { putElm, setArr } from '../../../actions/actions';
import Menu from '../../../components/Menu';

import { bindActionCreators } from 'redux';
import generateBlock from './generateBlock';

class AIList extends Component {

    constructor(props) {
        super(props);
        this.types = ['ai-list-1', 'ai-list-2', 'ai-list-3', 'ai-list-4', 'ai-list-5', 'ai-list-6', 'ai-list-7'];
        this.state = {

        };
    }

    getImg = () => {

        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "image") {
                return generateBlock(arr[i]);
            }
        }
    }
    getText = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "text") {
                return generateBlock(arr[i]);
            }
        }
    }
    getMarginTop = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.marginTop;
            }
        }
    }
    getMarginLeft = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.marginLeft;
            }
        }
    }
    getMarginRight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.marginRight;
            }
        }
    }
    getMarginBottom = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.marginBottom;
            }
        }
    }
    getPaddingTop = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.paddingTop;
            }
        }
    }
    getPaddingLeft = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.paddingLeft;
            }
        }
    }
    getPaddingRight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.paddingRight;
            }
        }
    }
    getPaddingBottom = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.paddingBottom;
            }
        }
    }
    getBackgroundColor = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.backgroundColor;
            }
        }
    }

    checkIfCanInteract = () => {
        return this.props.arr.filter(item => item.id === this.props.id)[0].params.for === -99;
    }

    editBlock = () => {
        this.props.putElm({ id: this.props.id, type: this.props.type })
    }

    deleteBlock = () => {
        const deletedBlock = this.props.arr.filter(item => item.id === this.props.id);
        if (deletedBlock[0].params.for !== -99) return;
        this.props.setArr(this.props.arr.filter(item => item.id !== this.props.id));
    }

    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    copyBlock = () => {
        let id = this.uuidv4();
        const block = this.props.arr.filter(item => item.id === this.props.id);
        const blockChildrens = this.props.arr.filter(item => item.params.for === block[0].id);
        const modifiedblockChildrens = blockChildrens.map(item => {
            return { ...item, id: this.uuidv4(), params: { ...item.params, for: id } }
        })
        let childrens = [];
        modifiedblockChildrens.forEach(item => childrens.push(item.id))
        const modifiedBlock = structuredClone(block.map(item => {
            return { ...item, id: id, childrens: [...childrens] }
        }))
        const res = [...modifiedblockChildrens, ...modifiedBlock];
        this.props.arr.push(...res);
        var arr = Array.prototype.concat(this.props.arr);
        this.props.setArr(arr);

    }

    elemMove = (direction) => {
        let arr = this.props.arr.filter(elem => elem.params.for === -99);
        let arrWithout99 = this.props.arr.filter(elem => elem.params.for !== -99);
        let selectedElemIndex = 0;
        let selectedElem = arr.find((elem, index) => {
            selectedElemIndex = index;
            return elem.id === this.props.id
        });
        let res = [];
        if (direction === 'up') {
            if (selectedElemIndex === 0) return;
            const arrCopy = [...arr];
            let element = arrCopy[selectedElemIndex];
            arrCopy.splice(selectedElemIndex, 1);
            arrCopy.splice(selectedElemIndex - 1, 0, element);
            res = arrCopy;
        }
        if (direction === 'down') {
            if (selectedElemIndex === this.props.arr.length - 1) return;
            const arrCopy = [...arr];
            let element = arrCopy[selectedElemIndex];
            arrCopy.splice(selectedElemIndex, 1);
            arrCopy.splice(selectedElemIndex + 1, 0, element);
            res = arrCopy;
        }
        this.props.setArr(res.concat(arrWithout99));
    }

    componentDidMount = () => {
        document.querySelectorAll('#moved-block')[0].classList.add('menu-moveBottom');

        var arr = JSON.parse(JSON.stringify(this.props.arr));

        arr = this.switchStyles(this.getType(), arr);
        this.props.setArr(arr);
    }

    componentWillUnmount = () => {
        document.querySelectorAll('#moved-block')[0].classList.remove('menu-moveBottom')
    }
    getborderTopLeftRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.borderTopLeftRadius;
            }
        }
    }
    getborderBottomLeftRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.borderBottomLeftRadius;
            }
        }
    }
    getborderTopRightRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.borderTopRightRadius;
            }
        }
    }
    getborderBottomRightRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.borderBottomRightRadius;
            }
        }
    }
    getIWidth = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "image") {
                return arr[i].params.width;
            }
        }
    }
    getIBackground = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "image") {
                return arr[i].params.backgroundColor;
            }
        }
    }
    getTBackground = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "text") {
                return arr[i].params.backgroundColor;
            }
        }
    }
    getAlign = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].params.align;
            }
        }
    }
    getIAlign = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "image") {
                return arr[i].params.align;
            }
        }
    }
    getTAlign = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "text") {
                return arr[i].params.align;
            }
        }
    }
    getDir = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {

                return arr[i].params.dir;
            }
        }
    }
    getTrueWidth = () => {
        var width = '602px';
        var arr = this.props.arr;

        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === '-122') {

                var params = arr[i].params;
                var pR = parseInt(params.paddingRight);
                var pL = parseInt(params.paddingLeft);

                width = 602 - pR - pL;
                width = width.toString() + 'px';
                break;
            }
        }
        return width;
    }
    setTypes = (reverse, arr) => {
        for (var j = 0; j < this.props.childrens.length; j++) {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === this.props.childrens[j]) {
                    if (reverse) {
                        arr[i].type = 'text-image';
                    } else {
                        arr[i].type = 'list-block';
                    }

                    break;
                }
            }
        }
        return arr;

    }
    getList = () => {
        var list = [];
        var arr = JSON.parse(JSON.stringify(this.props.arr));
        for (var j = 0; j < this.props.childrens.length; j++) {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === this.props.childrens[j]) {
                    list.push(generateBlock(arr[i]));
                    break;
                }
            }
        }
        // this.props.setArr(arr);
        return list;
    }
    getType = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                return arr[i].type;
            }
        }
    }

    switchStyles = (type, arr) => {
        
        switch (type) {
            case 'ai-list-1':
                arr = this.setBackgroundT('white', 'black', 'https://adbotest.digitum.nl/static/media/ai_list_dot1.png', '10px', '7px,0px,0px,0px', '0px,20px,0px,20px', '12px,32px,12px,32px', '601px', 'center', arr);
                arr = this.setTypes(false, arr);
                break;
            case 'ai-list-2':
                arr = this.setBackgroundT('black', 'white', 'https://adbotest.digitum.nl/static/media/ai_list_dot2.png', '48px', '0px,0px,0px,0px', '0px,0px,0px,20px', '12px,32px,40px,32px', '300px', 'left', arr);
                arr = this.setTypes(true, arr);
                break;
            case 'ai-list-3':
                arr = this.setBackgroundT('white', 'black', 'https://adbotest.digitum.nl/static/media/ai_list_dot3.png', '10px', '7px,0px,0px,0px', '0px,20px,0px,20px', '12px,32px,12px,32px', '601px', 'center', arr);
                arr = this.setTypes(false, arr);
                break;
            case 'ai-list-4':
                arr = this.setBackgroundT('#D5E6FB', 'black', 'https://adbotest.digitum.nl/static/media/ai_list_dot1.png', '10px', '7px,0px,0px,0px', '0px,20px,0px,20px', '12px,32px,12px,32px', '601px', 'center', arr);
                arr = this.setTypes(false, arr);
                break;
            case 'ai-list-5':
                arr = this.setBackgroundT('#E9FEB0', 'black', 'https://adbotest.digitum.nl/static/media/ai_list_dot3.png', '10px', '7px,0px,0px,0px', '0px,20px,0px,20px', '12px,32px,12px,32px', '601px', 'center', arr);
                arr = this.setTypes(false, arr);
                break;
            case 'ai-list-6':
                arr = this.setBackgroundT('#EFEFEF', 'black', 'https://adbotest.digitum.nl/static/media/ai_list_dot3.png', '10px', '7px,0px,0px,0px', '0px,20px,0px,20px', '12px,32px,12px,32px', '601px', 'center', arr);
                arr = this.setTypes(false, arr);
                break;
            case 'ai-list-7':
                arr = this.setBackgroundT('#C1F926', 'black', 'https://adbotest.digitum.nl/static/media/ai_list_dot4.png', '48px', '0px,0px,0px,0px', '12px,20px,0px,20px', '12px,32px,12px,32px', '601px', 'center', arr);
                arr = this.setTypes(false, arr);
                break;
            default:
                break;

        }
        return arr;
    }
    renderBlock = (type) => {
        switch (type) {
            case 'ai-list-1':
                return <>
                    {this.getText()}
                    {this.getList().map(x => x)}
                </>;
                break;
            case 'ai-list-2':
                return <>
                    {this.getText()}
                    {this.getTwoByTwo(this.getList()).map(x => x)}
                </>
                break;
            case 'ai-list-3':
                return <>
                    {this.getText()}
                    {this.getList().map(x => x)}
                </>;
                break;
            case 'ai-list-4':
                return <>
                    {this.getText()}
                    {this.getList().map(x => x)}
                </>;
                break;
            case 'ai-list-5':
                return <>
                    {this.getText()}
                    {this.getList().map(x => x)}
                </>;
                break;
            case 'ai-list-6':
                return <>
                    {this.getText()}
                    {this.getList().map(x => x)}
                </>;
                break;
            case 'ai-list-7':
                return <>
                    {this.getText()}
                    {this.getList().map(x => x)}
                </>;
                break;
            default:

                break;
        }
    }
    styleBack = () => {
        let type = this.getType();
        let num = parseInt(type.replace('ai-list-', ''));
        if (num <= 1) {
            return;
        } else {
            num--;
            type = 'ai-list-' + num.toString();
            var arr = JSON.parse(JSON.stringify(this.props.arr));
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === this.props.id) {
                    arr[i].type = type;
                    arr = this.switchStyles(type, arr);
                    this.props.setArr(arr);
                }
            }
        }
    }
    setImage = (src) => {
        var arr = JSON.parse(JSON.stringify(this.props.arr));

        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id) {
                if (arr[i].params.src === src) {
                    return;
                }
                arr[i].params.src = src;


            }
        }
        this.props.setArr(arr);
    }
    setBackgroundT = (color, tColor, img, width, paddingI, padding, paddingMain, mWidth, iAlign, arr) => {
        var main = false;
        var good_img = ['https://adbotest.digitum.nl/static/media/ai_list_dot1.png', 'https://adbotest.digitum.nl/static/media/ai_list_dot2.png', 'https://adbotest.digitum.nl/static/media/ai_list_dot3.png', 'https://adbotest.digitum.nl/static/media/ai_list_dot4.png'];
        var b_arr = arr;
        try {

            for (var i = 0; i < arr.length; i++) {
                if (main === false && arr[i].id === this.props.id) {
                    arr[i].params.backgroundColor = color;
                    main = true;

                }
                if (arr[i].params.for === this.props.id) {

                    arr[i].params.color = tColor;
                    arr[i].params.backgroundColor = color;
                    arr[i].params.paddingTop = paddingMain.split(',')[0];
                    arr[i].params.paddingLeft = paddingMain.split(',')[1];
                    arr[i].params.paddingBottom = paddingMain.split(',')[2];
                    arr[i].params.paddingRight = paddingMain.split(',')[3];
                    arr[i].params.width = mWidth;

                }
            }
            for (var i = 0; i < this.props.childrens.length; i++) {
                for (var j = 0; j < arr.length; j++) {
                    if (arr[j].id === this.props.childrens[i]) {
                        if (arr[j].params.backgroundColor === color || arr[j].params.color === tColor) {
                            // return;
                        }
                        arr[j].params.color = tColor;
                        arr[j].params.backgroundColor = color;
                    }
                    if (arr[j].params.for === this.props.childrens[i]) {
                        arr[j].params.color = tColor;
                        arr[j].params.backgroundColor = color;
                        arr[j].params.paddingTop = padding.split(',')[0];
                        arr[j].params.paddingLeft = padding.split(',')[1];
                        arr[j].params.paddingBottom = padding.split(',')[2];
                        arr[j].params.paddingRight = padding.split(',')[3];
                    }
                    if (arr[j].params.for === this.props.childrens[i] && arr[j].type === 'image') {
                        var n_img = good_img.filter(x=> x === arr[j].params.src).length;
                        arr[j].params.src = n_img > 0 ? img : arr[j].params.src;
                        arr[j].params.width = width;
                        arr[j].params.align = iAlign;
                        arr[j].params.paddingTop = paddingI.split(',')[0];
                        arr[j].params.paddingLeft = paddingI.split(',')[1];
                        arr[j].params.paddingBottom = paddingI.split(',')[2];
                        arr[j].params.paddingRight = paddingI.split(',')[3];
                    }
                }
            }
            console.log(arr);
            return arr;
        }
        catch (e) {
            console.log(e);
            return b_arr;
        }
    }
    styleForward = () => {
        let type = this.getType();
        let num = parseInt(type.replace('ai-list-', ''));
        if (num >= this.types.length) {
            return;
        } else {
            num++;
            type = 'ai-list-' + num.toString();

            var arr = JSON.parse(JSON.stringify(this.props.arr));
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === this.props.id) {
                    arr[i].type = type;
                    arr = this.switchStyles(type, arr);
                    this.props.setArr(arr);
                }
            }
        }
    }
    getTwoByTwo = (list) => {
        var arr = [];
        for (var i = 0; i < list.length; i += 2) {
            if (i + 2 <= list.length) {
                arr.push(
                    <tr>
                        <td style={{ verticalAlign: 'top' }}>{list[i]}</td>
                        <td style={{ verticalAlign: 'top' }}>{list[i + 1]}</td>
                    </tr>
                );
            } else {
                arr.push(
                    <tr>
                        <td style={{ verticalAlign: 'top' }}>{list[i]}</td>
                        <td></td>
                    </tr>
                );
            }

        }
        return arr;
    }
    testStyle = () => {

    }
    render() {
        return (
            <table
                name={this.props.id} onMouseEnter={(e) => {
                    var btns = document.getElementsByName(this.props.id)[0].getElementsByClassName('change-buttonts')[0];
                    btns.style.visibility = '';
                    btns.style.opacity = 1;
                }} onMouseLeave={(e) => {
                    var btns = document.getElementsByName(this.props.id)[0].getElementsByClassName('change-buttonts')[0];
                    btns.style.visibility = 'hidden';
                    btns.style.opacity = 0;
                }}
                data-content='List' id='moved-block' className='higlighted__parentElem' width="602px" cellspacing="0" align={this.getAlign()} cellpadding="0" style={{
                    maxWidth: this.getTrueWidth(), width: this.getTrueWidth(),
                    border: this.props.selectedElm === this.props.id ? '2px solid #34b9a0' : '',
                    borderTopLeftRadius: this.getborderTopLeftRadius(),
                    borderBottomLeftRadius: this.getborderBottomLeftRadius(),
                    borderTopRightRadius: this.getborderTopRightRadius(),
                    borderBottomRightRadius: this.getborderBottomRightRadius(),
                    paddingTop: this.getPaddingTop(),
                    paddingBottom: this.getPaddingBottom(),

                    backgroundColor: this.getBackgroundColor()
                }} onClick={(e) => e.target.tagName === "TD" ? this.props.putElm({ id: this.props.id, type: this.props.type }) : null}>
                {this.renderBlock(this.getType())}
                <div className='change-buttonts' style={{ position: 'absolute', zIndex: 1, top: '8px', right: 32, visibility: 'hidden', opacity: '0', transition: '0.3s all' }}>
                    <img onClick={() => this.styleBack()} Style="cursor: pointer;background: #e6e6e68a;border-radius: 100%;margin-right: 16px;" src="https://adbotest.digitum.nl/static/media/ai_back.svg" />
                    <img onClick={() => this.styleForward()} Style="cursor: pointer;background: #e6e6e68a;border-radius: 100%;" src="https://adbotest.digitum.nl/static/media/ai_forward.svg" />
                </div>

                <Menu
                    parent
                    edit={this.editBlock}
                    canInteract={this.checkIfCanInteract()}
                    delete={this.deleteBlock}
                    copy={this.copyBlock}
                    moveUp={() => this.elemMove('up')}
                    moveDown={() => this.elemMove('down')}
                />
            </table>
        );
    }

}
/*

<Section data-content='List' id='moved-block' className='higlighted__parentElem' style={{
                paddingTop: this.getPaddingTop(), paddingBottom: this.getPaddingBottom(),
                border: this.props.selectedElm === this.props.id ? '2px solid #34b9a0' : '',
                paddingLeft: this.getPaddingLeft(), paddingRight: this.getPaddingRight(),
                borderTopLeftRadius: this.getborderTopLeftRadius(),
                borderBottomLeftRadius: this.getborderBottomLeftRadius(),
                borderTopRightRadius: this.getborderTopRightRadius(),
                borderBottomRightRadius: this.getborderBottomRightRadius(),
                backgroundColor: this.getBackgroundColor(),
                marginTop: this.getMarginTop(), marginBottom: this.getMarginBottom(), marginLeft: this.getMarginLeft(),
                marginRight: this.getMarginRight(),
            }} onClick={(e) => e.target.tagName === "TD" ? this.props.putElm({ id: this.props.id, type: this.props.type }) : null}>
                <Column style={{ verticalAlign: 'top' }}>{this.getImg()}</Column>
                <Column>{this.getText()}</Column>
                <Menu
                    parent
                    edit={this.editBlock}
                    canInteract={this.checkIfCanInteract()}
                    delete={this.deleteBlock}
                    copy={this.copyBlock}
                    moveUp={() => this.elemMove('up')}
                    moveDown={() => this.elemMove('down')}
                />
            </Section>









            <tr style={{
                    display: 'block', marginTop: this.getMarginTop(), marginLeft: this.getMarginLeft(), marginRight: this.getMarginRight(),
                    marginBottom: this.getMarginBottom()
                }}>
                    {this.getDir() ? <>

                        <td align={this.getTAlign()} style={{
                            borderTopRightRadius: this.getborderTopRightRadius(),
                            width: '100%',
                            borderBottomRightRadius: this.getborderBottomRightRadius(),
                            backgroundColor: this.getTBackground(), paddingTop: this.getPaddingTop(), paddingRight: this.getPaddingRight(),
                            paddingBottom: this.getPaddingBottom(),
                        }}>{this.getText()}</td>
                        <td align={this.getIAlign()} style={{
                            borderTopLeftRadius: this.getborderTopLeftRadius(),
                            borderBottomLeftRadius: this.getborderBottomLeftRadius(),
                            verticalAlign: 'top', paddingTop: this.getPaddingTop(), paddingLeft: this.getPaddingLeft(),
                            paddingBottom: this.getPaddingBottom(),
                            width: this.getIWidth(),
                            backgroundColor: this.getIBackground()
                        }}>{this.getImg()}</td>
                    </> : <><td align={this.getIAlign()} style={{
                        borderTopLeftRadius: this.getborderTopLeftRadius(),
                        borderBottomLeftRadius: this.getborderBottomLeftRadius(),
                        verticalAlign: 'top', paddingTop: this.getPaddingTop(), paddingLeft: this.getPaddingLeft(),
                        paddingBottom: this.getPaddingBottom(),
                        width: this.getIWidth(),
                        backgroundColor: this.getIBackground()
                    }}>{this.getImg()}</td>
                        <td align={this.getTAlign()} style={{
                            borderTopRightRadius: this.getborderTopRightRadius(),
                            width: '100%',
                            borderBottomRightRadius: this.getborderBottomRightRadius(),
                            backgroundColor: this.getTBackground(), paddingTop: this.getPaddingTop(), paddingRight: this.getPaddingRight(),
                            paddingBottom: this.getPaddingBottom(),
                        }}>{this.getText()}</td></>}

                </tr>
*/

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ putElm: putElm, setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr,
        selectedElm: state.selectedElm
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(AIList);