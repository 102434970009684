import { Row } from "@react-email/row";
import { getListBlock } from "./listBlock";
import { getTextImage } from "./textImage";
import { renderToStaticMarkup } from "react-dom/server";
import { getTextBlock } from "./textBlock";

function getTwoByTwo(list) {
    var arr = [];
    for (var i = 0; i < list.length; i += 2) {
        if (i + 2 < list.length) {
            arr.push(
                <table cellPadding={0} cellSpacing={0} style={{ width: '600px' }} width={600}>
                    <tr style={{ background: list[i].main.params.backgroundColor }}>
                        <td style={{ padding: '0', margin: '0', verticalAlign: 'top' }}>{list[i].data}</td>
                        <td style={{ padding: '0', margin: '0', verticalAlign: 'top' }}>{list[i + 1].data}</td>
                    </tr>
                </table>
            );
        } else {
            arr.push(
                <table cellPadding={0} cellSpacing={0} style={{ width: '600px' }} width={600}>
                    <tr style={{ background: list[i].main.params.backgroundColor }}>
                        <td style={{ padding: '0', margin: '0', verticalAlign: 'top' }}>{list[i].data}</td>
                        <td></td>
                    </tr>
                </table>
            );
        }

    }
    return arr;
}
function getClearTwoByTwo(list, params) {
    var arr = [];
    for (var i = 0; i < list.length; i += 2) {
        if (i + 2 <= list.length) {
            arr.push(
                <tr  style={{ background: list[i].main.params.backgroundColor }}>
                    <td style={{ paddingRight: params.b2_paddingRight, paddingBottom: params.b2_paddingBottom, margin: '0', verticalAlign: 'top' }}>{list[i].data}</td>
                    <td style={{ paddingBottom: params.b2_paddingBottom, margin: '0', verticalAlign: 'top' }}>{list[i + 1].data}</td>
                </tr>
            );
        } else {
            arr.push(

                <tr style={{ background: list[i].main.params.backgroundColor }}>
                    <td style={{ paddingBottom: params.b2_paddingBottom, margin: '0', verticalAlign: 'top' }}>{list[i].data}</td>
                    <td></td>
                </tr>
            );
        }

    }
  //  console.log(renderToStaticMarkup(arr));
    return arr;
}
function getList(arr, full_arr, main, double) {
    var list = [];
  
    for (var i = 0; i < arr.length; i++) {
        if (arr[i].main.type === 'list-block') {
           
            if (main.type === 'ai-offers-3') {
                list.push({ main: arr[i].main, data: getListBlock(arr[i].img, arr[i].text, arr[i].main.params, arr[i].main.params, false, double, false) });
            } 
            else if (main.type === 'ai-offers-1'){
                
                list.push({ main: arr[i].main, data: <tr><td style={{ paddingBottom: arr[i].main.params.paddingBottom }}>{getListBlock(arr[i].img, arr[i].text, arr[i].main.params, arr[i].main.params, true, undefined, '500px')}</td></tr> })
            }
            else {
             
                list.push({ main: arr[i].main, data: <tr><td style={{ paddingBottom: arr[i].main.params.paddingBottom }}>{getListBlock(arr[i].img, arr[i].text, arr[i].main.params, arr[i].main.params, true)}</td></tr> })
            }

        }
        else if (arr[i].main.type === 'text-image') {
           
            list.push({ main: arr[i].main, data: getTextImage(arr[i].img, arr[i].text, arr[i].main.params, null, full_arr, true) });
        } else {
           
            list.push(<></>);
        }
    }
   
    
    return list;
}
function getTextList(arr, parent) {
    var list = [];

    for (var i = 0; i < arr.length; i++) {
        if (arr[i].params.for === parent.id && arr[i].type === 'text') {
            var block = getTextBlock(arr[i].params, undefined, undefined, undefined, undefined, '260px');
            
            list.push({main: parent, data: block});
        }
    }
    return list;
}
function getContent(arr, type, full_arr, main) {
    switch (type.type) {
        case 'ai-offers-1':
            return <>

                {getList(arr, full_arr, type).map(x => x.data)}
            </>;
            break;
        case 'ai-offers-2':
            return <>

                {getClearTwoByTwo(getList(arr, full_arr), main).map(x => x)}
            </>
            break;
        case 'ai-offers-3':
            return <>

                {getClearTwoByTwo(getList(arr, full_arr, type, true), main).map(x => x)}
            </>;
            break;
        case 'ai-offers-4':
            return <>

                {getClearTwoByTwo(getTextList(full_arr, type), main).map(x => x)}
            </>;
            break;
        case 'ai-offers-5':
            return <>

                {getList(arr, full_arr).map(x => x.data)}
            </>;
            break;
        case 'ai-offers-6':
            return <>

                {getList(arr, full_arr).map(x => x.data)}
            </>;
            break;
        default:

            break;
    }
    return <></>;
}

export const getAiOffersBlock = (params, arr, type, full_arr) => {

    var content = getContent(arr, type, full_arr, params);
    //  console.error(renderToStaticMarkup(content));
    return <table cellPadding={0} width={600} cellSpacing={0} className="offers" style={{
        paddingTop: params.paddingTop, paddingLeft: params.paddingLeft, paddingRight: params.paddingRight, paddingBottom: params.paddingBottom,
        marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom, backgroundColor: params.backgroundColor,
        width: '600px', maxWidth: '600px'
    }}>

        {content}

    </table>;
}